import S3 from 'aws-sdk/clients/s3'
import {config} from 'aws-sdk/lib/core'
import * as Sentry from '@sentry/react'
import uuid from 'uuid/v4'

import {trackCustom} from 'utils/Tracker'
import {
  ASSETS_BUCKET as Bucket
} from 'utils/env'

// deobfuscator terms are odd to make it harder to deduce
const daysin = (cal, len) => cal.split('').reduce((acc, letter, i) => {
  if (i % 2 === 0) acc.push(letter)
  return acc
}, []).slice(0, len).reverse().join('')
const a = 'accessKeyId'
const b = 'secretAccessKey'

const _upload = ({s3key: Key, body: Body, progress, cancelSignal, oid, uid, locale = 'us'}) => {
  if (cancelSignal && cancelSignal.aborted) {
    trackCustom({category: 'User', action: 'Canceled Upload', data: {uid, oid, locale}})
    return Promise.reject(new Error('Upload cancelled'))
  }
  const region = locale === 'us' ? 'us-east-1' : 'eu-west-1'
  // final is access id
  const final = daysin('A7F3XAW5XF6F65ODX4K9RCR2J4L7E8R6A9IIKCAD', 20)
  // join is secretaccesskey
  const join = daysin('t7G3DAa5XFhFQ5WDF4596CX2o4l7K8y6F9gI3CmDMJERlYtU2IfITYcY35mCM8ABZ499o2KCK4sDTAmE', 40)
  // handle uploader
  return new Promise((resolve, reject) => {
    const params = {Key, Body, Bucket: `${locale === 'us' ? '' : 'eu.'}${Bucket}`}
    config.update({region, [a]: final, [b]: join})
    // console.log({params})
    const upload = new S3.ManagedUpload({params})
    upload.on('httpUploadProgress', progress)
    upload.send((err, data) => {
      if (err) {
        trackCustom({category: 'Upload', action: 'Error', data: {uid, oid, err, locale}})
        Sentry.captureException(err)
        reject(err)
      } else resolve(data)
    })

    cancelSignal && cancelSignal.addEventListener('abort', () => {
      trackCustom({category: 'User', action: 'Canceled Upload', data: {uid, oid, locale}})
      upload.abort()
      reject(new Error('Upload cancelled'))
    })
  })
}

export const upload = async ({blob, uid, oid, locale = 'us', progress, cancelSignal}) => {
  console.log('upload', {blob, locale, progress, cancelSignal})
  if (cancelSignal && cancelSignal.abort) throw new Error('Upload cancelled')
  var fileName = uuid() + '.webm'
  if (blob.name) {
    const ext = blob.name.split('.').pop()
    fileName = uuid() + '.' + ext
  }
  // console.log({blob}, fileName)
  cancelSignal && cancelSignal.addEventListener('abort', () => new Error('Upload cancelled'))
  // console.log('cancel')
  const response = await _upload({
    s3key: `uploads/${uid}/${fileName}`, 
    body: blob,
    progress,
    uid,
    oid,
    cancelSignal,
    locale
  })
  if (response.Location) {
    const tempUrl = response.Location
    trackCustom({category: 'Upload', action: 'Completed', data: {uid, tempUrl, oid, locale}})
    return ({tempUrl: tempUrl})
  }
}

/*
// Use this to create new onvfuscated keys from original
original = 'AKIARELJRRKXO66XWXFA' // mTsKKo9ZAMm3cTf2tlEMm3gFyKloX65FWQhXaDGt
obfuscate = (key, scramble) => key.split('').reverse().reduce((acc, letter, i) => {
  acc.push(letter)
  acc.push(scramble.split('')[i])
  return acc
}, []).join('')
a = obfuscate(original, '73A5FF5D49C247869ICDJRYUIIYY5C8B492C4DAE021')

deobfuscate = (key) => key.split('').reduce((acc, letter, i) => {
  if (i%2 === 0) acc.push(letter)
  return acc
}, []).slice(0,20).reverse().join('')
b = deobfuscate(a)
original == b
 */
/*
  this.startProcess(response.Location, webhook, languageCode)
                .then((data) => {
                  resolve({id: data.result, tempUrl: tempUrl})
                })
                .catch(reject)
 */
