import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import {
  fetchPeaks as fetchPeaksApi,
  generatePeaks as generatePeaksApi
} from 'api/peaks'

// Slice
export const peaksAdapter = createEntityAdapter()
const initialState = peaksAdapter.getInitialState({
  pendingRequests: []
})

export const fetchPeaks = createAsyncThunk(
  'peaks/fetch',
  async ({collectionId, peaksUrl, locale}) => {
    try {
      const {data} = await fetchPeaksApi({collectionId, peaksUrl, locale})
      // console.log('generate peaks', data)
      if ((data || {}).data === null) await generatePeaksApi({collectionId})
      return data
    } catch (error) {
      console.log(error)
      return {}
    }
  }
)

const sliceSelector = state => state.peaks
const {selectById} = peaksAdapter.getSelectors(sliceSelector)
export const selectPeaksById = selectById

export const selectCollectionPeaks = createSelector(
  [selectPeaksById],
  peak => peak
)

export const slice = createSlice({
  name: 'peaks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPeaks.fulfilled, (state, args) => {
        const {meta, payload} = args
        // console.log({args})
        const {arg: {collectionId: id}} = meta
        peaksAdapter.upsertOne(state, {id, ...payload})
      })
  }
})

const reducer = slice.reducer
export default reducer
