import React from 'react'
import SVGInline from 'react-svg-inline'

const svg = `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="64px" height="80px" viewBox="0 0 64 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="FInal" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd" opacity="0.599999964">
            <g id="upload" transform="translate(-608.000000, -312.000000)" fill="#D8D8D8">
                <g id="Group-3" transform="translate(608.000000, 312.000000)">
                    <path d="M2,3.08931624e-16 L44,7.10542736e-15 C45.1045695,6.90252123e-15 46,0.8954305 46,2 L46,16 C46,17.1045695 46.8954305,18 48,18 L62,18 C63.1045695,18 64,18.8954305 64,20 L64,78 C64,79.1045695 63.1045695,80 62,80 L2,80 C0.8954305,80 1.3527075e-16,79.1045695 0,78 L-2.22044605e-16,2 C-3.57315355e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M27.6584856,28.0486161 C27.3314766,27.832663 26.94823,27.7175366 26.5563492,27.7175366 C25.4517797,27.7175366 24.5563492,28.6129671 24.5563492,29.7175366 L24.5563492,53.3951618 C24.5563492,53.7870427 24.6714757,54.1702892 24.8874287,54.4972982 C25.4961219,55.4190175 26.7367662,55.6727754 27.6584856,55.0640823 L45.5855198,43.2252696 C45.810759,43.0765242 46.0035584,42.8837248 46.1523039,42.6584856 C46.760997,41.7367662 46.5072391,40.4961219 45.5855198,39.8874287 L27.6584856,28.0486161 Z" id="Combined-Shape"></path>
                    <path d="M50.7071068,1.70710678 L62.2928932,13.2928932 C62.6834175,13.6834175 62.6834175,14.3165825 62.2928932,14.7071068 C62.1053568,14.8946432 61.8510029,15 61.5857864,15 L50,15 C49.4477153,15 49,14.5522847 49,14 L49,2.41421356 C49,1.86192881 49.4477153,1.41421356 50,1.41421356 C50.2652165,1.41421356 50.5195704,1.5195704 50.7071068,1.70710678 Z" id="Rectangle-14"></path>
                </g>
            </g>
        </g>
    </svg>
`

export default (props) => <SVGInline svg={svg} {...props} />
