import client from 'api/client'

const API = 'correction/v2'

export const list = ({locale = 'us', uid}) => 
  client().get(`${API}/corrector/${uid}/queue`, {locale})

export const fetchMyStats = ({locale = 'us', uid, language = 'en'}) => 
  client().get(`${API}/corector/${uid}/stats/${language}/`, {locale})

export const transcript = ({locale = 'us', id}) => 
  client().get(`${API}/transcript/${id}/`, {locale})

export const complete = ({locale = 'us', id, completionTime, comment, rating}) => 
  client().post(`${API}/transcript/${id}/complete/`, {id, locale, completionTime, comment, rating})

export const rereview = ({locale = 'us', notes, language, id}) => 
  client().post(`${API}/transcript/${id}/rereview/`, {id, locale, notes, reject})

export const pause = ({id, locale = 'us'}) => 
  client().post(`${API}/transcript/${id}/pause`, {locale})

export const reject = ({id, locale = 'us'}) => 
  client().post(`${API}/transcript/${id}/reject`, {locale})

export const release = ({locale = 'us', id}) => 
  client().post(`${API}/transcript/${id}/release/`, {locale})
  
export const take = ({locale = 'us', id}) => 
  client().post(`${API}/transcript/${id}/take/`, {locale})
  
export const fetch = ({locale = 'us', language = 'en', test = false}) => 
  client().get(`${API}/queue/${language}/pop`, {locale, test})

export const count = ({locale = 'us', language = 'en'}) => 
  client().get(`${API}/queue/${language}/count`, {locale})

export const progress = ({locale = 'us', language = 'en'}) => 
  client().get(`${API}/queue/${language}`, {locale})

export const stats = ({locale = 'us', language = 'en'}) => 
  client().get(`${API}/queue/${language}/stats/admin`, {locale})

export const fetchLeaderboard = ({locale = 'us', language = 'en',date}) => 
  client().get(`${API}/reports/${language}/leaderboard`, {locale, params: {date}})
  