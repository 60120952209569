import React from 'react'
import styled from 'styled-components'
import FadeIn from 'react-fade-in'
import {Button} from '@material-ui/core'
import useProfile from 'hooks/useProfile'
import Loader from 'components/common/Loaders/Skeleton'
import useUpload from 'hooks/useUpload'

const FilterOptions = {
  'My Collections': 'My transcripts',
  'Shared with Me': 'Shared with me'
}

const OrgAdminFilterOptions = {
  'Org Collections': 'Team transcripts',
  ...FilterOptions
}

const FilterSelector = styled(Button)`
  && {
    text-transform: uppercase;
    padding: 0px 0;
    ${props => props.onClick && 'cursor: pointer;'} 
    transition: color .5s;
    width: 183px;
    margin: unset;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 32px;
    color: #37404A;
    padding-left: 10px;
    span {
      display: block;
      text-align: left;
    }
    opacity: ${props => props.selected ? 1 : 0.6};
  }
`

export default (props) => {
  const {settings = {}, updateSettings, organization, isOwner, oid} = useProfile()
  const {numberOfUploads} = useUpload()
  // to make Anders sleep better, we are hard coding this restriction. Sorry for the problem this caused ;-D
  const isDEiC = oid === '-deicunikeyfororg09252018'
  isDEiC && console.log('DEiC Org, so manually blocking filter options.')
  // show Team Wide view is isOwner and NOT DEiC
  const views = Object.entries(isOwner && !isDEiC ? OrgAdminFilterOptions : FilterOptions)
  const filter = settings?.dashFilter
  // console.log('filter', filter, settings)
  return (
      
    organization.isLoaded
      ? views.map(([key, value], i) => {
        const uploadIndicator = numberOfUploads && key === 'My Collections' && filter === 'Shared with Me' ? `[ ${numberOfUploads} ]` : ''
        const selected = filter === key || (key === 'My Collections' && !filter)
        return (
          <FadeIn key={key} delay={i * 200}>
            <FilterSelector key={key} selected={selected} onClick={() => updateSettings({dashFilter: key})} >
              {value} {uploadIndicator}
            </FilterSelector>
          </FadeIn>
        )
      })
      : <div><p><Loader width='200px' /></p><p><Loader width='180px' /></p></div>
    
  )
}
