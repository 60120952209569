import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

import _omit from 'lodash/omit'
import _set from 'lodash/set'
import _unset from 'lodash/unset'

import {
  addHighlight as addHighlightApi,
  fetchHighlights as fetchHighlightsApi,
  removeHighlight as removeHighlightApi,
  updateHighlight as updateHighlightApi
} from 'api/highlights'

// Slice
export const highlightsAdapter = createEntityAdapter()
const initialState = highlightsAdapter.getInitialState({
  pendingRequests: []
})

// Selectors
const sliceSelector = state => state.highlights
const {selectById} = highlightsAdapter.getSelectors(sliceSelector)

export const selectHighlightById = selectById

export const selectCollectionHighlights = createSelector(
  [selectHighlightById],
  highlights => _omit(highlights, 'id')
)

// Actions
export const addHighlight = createAsyncThunk(
  'highlights/addOne',
  async ({collectionId, highlightId, content, tags}) => {
    try {
      const data = {content, tags}
      await addHighlightApi({collectionId, highlightId, data})
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateHighlight = createAsyncThunk(
  'highlights/updateOne',
  async ({collectionId, highlightId, content, tags}) => {
    try {
      const data = {content, tags}
      await updateHighlightApi({collectionId, highlightId, data})
    } catch (error) {
      console.log(error)
    }
  }
)

export const fetchHighlights = createAsyncThunk(
  'highlights/fetchAll',
  async ({collectionId, locale}) => {
    try {
      const {data} = await fetchHighlightsApi({collectionId, locale})
      data.id = collectionId
      return data
    } catch (error) {
      console.log('unable to fetch highlights', collectionId, error)
    }
  }
)

export const removeHighlight = createAsyncThunk(
  'highlights/removeOne',
  async ({collectionId, highlightId}) => {
    try {
      await removeHighlightApi({collectionId, highlightId})
    } catch (error) {
      console.log(error)
    }
  }
)

export const slice = createSlice({
  name: 'highlights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests.push(requestId)
    }

    const removeRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests = state.pendingRequests.filter(id => id !== requestId)
    }

    builder
      .addCase(addHighlight.pending, (state, args) => {
        const {meta: {arg: {collectionId, highlightId, content, tags}}} = args
        _set(state, ['entities', collectionId, highlightId], {content, tags})
        addRequestId(state, args)
      })
      .addCase(addHighlight.fulfilled, removeRequestId)
      .addCase(addHighlight.rejected, (state, args) => {
        const {meta: {arg: {collectionId, highlightId}}} = args
        _unset(state, ['entities', collectionId, highlightId])
        removeRequestId(state, args)
      })
      .addCase(removeHighlight.pending, (state, args) => {
        const {meta: {arg: {collectionId, highlightId}}} = args
        _unset(state, ['entities', collectionId, highlightId])
        addRequestId(state, args)
      })
      .addCase(removeHighlight.fulfilled, removeRequestId)
      .addCase(removeHighlight.rejected, removeRequestId)
      .addCase(updateHighlight.pending, (state, args) => {
        const {meta: {arg: {collectionId, highlightId, content, tags}}} = args
        _set(state, ['entities', collectionId, highlightId], {content, tags})
        addRequestId(state, args)
      })
      .addCase(updateHighlight.fulfilled, removeRequestId)
      .addCase(updateHighlight.rejected, removeRequestId)
      .addCase(fetchHighlights.fulfilled, (state, {meta, payload}) => {
        highlightsAdapter.upsertOne(state, payload)
      })
      .addCase('collections/fetch/fulfilled', (state, {meta, payload}) => {
        const {arg: {id: collectionId, versionId} = {}} = meta

        if (!versionId) return

        const {highlights = {}} = payload 
        highlights.id = collectionId
        _set(state, ['entities', collectionId], highlights)
      })
  }
})

const reducer = slice.reducer
export default reducer
