import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import {fetchVersions as fetchVersionsApi} from 'api/versions'

// Actions
export const fetchVersions = createAsyncThunk(
  'versions/fetchAll', 
  async (id, locale) => {
    try {
      const {data} = await fetchVersionsApi(id, locale)
      return {id, versions: data}
    } catch (error) {
      console.log(error)
    }
  })

// Slice
export const versionsAdapter = createEntityAdapter()
const initialState = versionsAdapter.getInitialState({
  loading: [],
})

export const slice = createSlice({
  name: 'versions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addLoadingId = (state, {meta}) => {
      const {arg: id} = meta
      state.loading.push(id)
    }

    const removeLoadingId = (state, {meta}) => {
      const {arg: id} = meta
      state.loading = state.loading.filter(_id => _id !== id)
    }

    builder.addCase(fetchVersions.pending, addLoadingId)
    builder.addCase(fetchVersions.rejected, removeLoadingId)
    builder.addCase(fetchVersions.fulfilled, (state, {meta, payload}) => {
      removeLoadingId(state, {meta})
      if (payload) versionsAdapter.upsertOne(state, payload)
    })
  }
})

const reducer = slice.reducer
export default reducer

// Selectors
const sliceSelector = state => state.versions
const {selectById} = versionsAdapter.getSelectors(sliceSelector)

export const selectVersionsByCollectionId = createSelector(
  [selectById],
  ({versions = []} = {}) => versions
)

export const selectAreVersionsLoading = createSelector(
  [sliceSelector, (_, id) => id],
  ({loading}, id) => loading.includes(id)
)
