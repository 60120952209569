import React from 'react'
import styled from 'styled-components'
import {getTimeStamp} from 'components/common/Timer/convert-time'
import ResolveAllCheckIcon from '@material-ui/icons/CheckCircleOutline'

const TimeStamp = React.memo(function TimeStamp ({time, onMarkAllAsResolved}){
  const showHumanTime = (secs) => {
    // const timeDict = ConvertTime(secs)
    // return `${timeDict.hh}:${timeDict.mm}:${timeDict.ss}`
    return getTimeStamp(secs)
  }

  return (
    <TimeCode>
      <h1>{showHumanTime(time)} </h1>
      <ResolveAllCheckIcon onClick={onMarkAllAsResolved}/>
    </TimeCode>
  )
})

export default TimeStamp

const TimeCode = styled.div.attrs({
  contentEditable: false
})`
  user-select: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 100%;
  margin: -10px 8px 0 0;
  h1 {
    color: #979797;
    font-weight: bold;
    font-size: 15px;
    font-family: 'Roboto Mono', 'Roboto', sans-serif;
  }
  svg {
    margin-left: 6px;
    opacity: 0;
    font-size: 18px;
    cursor: pointer;
    background-color: #FFF;
    border-radius: 50%;
    color: #979797;
    transition: color 1s, opacity .3s .3s, background-color .5s;
    :hover {
      opacity: 1;
      color: #159115;
      transition: color 1s, background-color .5s;
    }
    :active {
      background-color: #077307;
      color: #FFF;
    }
  }
  :hover svg {
    opacity: 1;
    transition: opacity .3s .3s;
  }
  
`

