import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import useProfile from 'hooks/useProfile'
import {getCurrentEnv, getCurrentAPIEnv, FUNCTIONS_URL} from 'utils/env'

const Root = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  border-top: 2px solid orange;
`

const Tray = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .5s;
  transition-delay: 1s;
  ${({loaded}) => !loaded ? 'transform: translateY(-40px)' : 'transform: translateY(0);'}

`

const Banner = styled.div`
  text-align: center;
  pointer-events: all;
  padding: 3px 10px 5px;
  border-bottom-left-radius: 4px;
  background: orange;
  color: #FFF;
  font-size: .5em;
  font-weight: bold;
  transition: background 1s;
  transition-delay: 1s;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  transform: translateY(-27px);
  transition: transform .5s;
  transition-delay: .6s;
  &:hover {
    transform: translateY(-5px);
  }

`
export default () => {
  const {organization, uid} = useProfile()
  const [hide, setHide] = useState(false)
  const locale = organization.locale || 'Not EU'
  return (
    !window.location.pathname.includes('edit-v2') && !hide
      ? <Root >
        <Tray loaded={uid}>
          <Banner>
            <p>{FUNCTIONS_URL.split('/').slice(2, 4).join('/')}</p>
            <Link to='/d'>TESTING &nbsp;&nbsp; {getCurrentEnv().toUpperCase()} &rarr; {getCurrentAPIEnv().toUpperCase()} &nbsp;&nbsp; [ {locale.toUpperCase()} ]</Link>
            &nbsp;&nbsp;&nbsp;
            <span 
              tabIndex={0}
              role="button" 
              styling="text"
              onKeyDown={(evt) => evt.keyCode === 13 && setHide(true)}
              style={{outline: 'none', cursor: 'pointer'}}
              onClick={() => setHide(true)}
            >
            HIDE
            </span>
          </Banner>
        </Tray>
      </Root> : ''
  )
}
