import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components'
import _debounce from 'lodash/debounce'
import FadeIn from 'react-fade-in'

import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/ErrorOutlineRounded'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Button from 'components/common/Button'
import {LanguageLookup, LanguageIndexByCode} from 'components/common/CreatorDialog/SelectLanguage'
import ConfirmDialog from 'components/ConfirmDialog'
import ProgressIcon from 'components/common/Dashboardv2/ProgressIcon'
import ArrowIcon from 'components/common/Dashboardv2/ArrowIcon'
import useUpload from 'hooks/useUpload'

const StyledIconButton = styled(IconButton)`
  margin-left: 0;
  padding: 0;
`

const StyledProgressIcon = styled(ProgressIcon)`
  color: green;
  margin: 3px 0 0 0;
`

const StyledErrorIcon = styled(WarningIcon)`
  color: red;
`

const EditUpload = styled(Button)`
  opacity: 0;
  color: #7DBB91;
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: #7DBB91;
    display: flex;
    align-items: center;
    padding: unset;
    min-width: 44px;
    height: 25px;
    span {
      display: block;
      text-align: center;
    }
  }
  transition: opacity .8s !important;
`

const CancelButton = styled(Button)`
  font-family: Roboto;
  opacity: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  color: #BBB;  
  text-transform: uppercase;
  font-size: 13px;
  padding: 0;
  height: 20px;
  &:hover {
    color: #769ADF;
    transition: color .5s !important;
  }
  transition: opacity 1s, color .5s !important;
`

const TextSpan = styled.span`
  display: inline;
  flex-grow: 2;
  margin-left: 0px;
  color: #37404A;
  line-height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 125px;
  transition: all 1s;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  & svg {
    transition: all 1s;
  }
  :hover svg, :hover ${TextSpan} {
    fill: #7DBB91 !important;
    color: #7DBB91 !important;
    transition: all .5s;
  }
`

const RootCont = styled.div`
  position: fixed;
  z-index: 54;
  bottom: 60px;
  ${props => !props.small ? `
    width: 350px;
  ` : `
    bottom: 80px;
  `
}
  
  ${props => props.show ? `
    opacity 1;
    transform: translateX(20px);
  ` : `
    opacity 0;
    transform: translateX(-400px);
  `}
  transition: transform .8s, opacity .8s, height 1s;
`

const Root = styled.div`
  z-index: 0;
  border: 1px solid rgba(0,0,0,.08);
  position: relative;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 32px;
  box-shadow: 0 2px 3px rgba(0,0,0,.05);
  background: white;
  border-radius: 6px;
  padding: 20px;
  padding-top: 16px;
  cursor: pointer;
  transition: all ease 200ms;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0,0,0,.05);
  opacity: .9;
  min-height: 131px;
  :hover {
    opacity: 1;
    box-shadow: 0 2px 15px rgba(0,0,0,.1);
    transition: opacity .5s, box-shadow .5s;
  }
  &:hover ${CancelButton} {
    opacity: 1;
    transition: opacity .8s, color 1s !important;
  }
  &:hover ${StyledIconButton} {

  }
  ${props => props.deleting && `
    transition: opacity 1s, transform 1s;
    opacity: 0.2 !important;
    transform: scale(.50) !important;
  `}

  ${props => props.isError && `
    background: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, 0.4);
  `}
  transition: opacity 1s linear 2s, box-shadow 1s linear 2s;
`

const SelectLanguageIcon = styled(ArrowRightIcon)`
  opacity: .8;
  fill: #7DBB91 !important;
  transform: translateX(0);
  :hover {
    transform: translateX(9px);
    opacity: 1;
    transition: all .2s linear .2s !important;
  }
  transition: all 1s linear .2s !important;
`

const LanguageNotice = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #7DBB91;
  display: flex;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
`

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 40px;
  color: #7DBB91;
  display: flex;
  align-items: center;
`

const SingleUpload = styled.div`
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  margin-bottom: 4px;
  &:hover ${EditUpload} {
    opacity: 1;
    transition: opacity .8s !important;
  }
  ${({selected}) => selected ? 'background-color: rgba(100,100,100,0.03);' : ''}
  ${({complete}) => complete ? 'opacity: .9;' : ''}
  transition: opacity 1s;
`
const Actions = styled.div`
  display: flex;
  align-items: center;
  &:hover ${SelectLanguageIcon} {
    transform: translateX(3px);
    opacity: 1;
    transition: all .2s !important;
  }
`

const Span = styled.span`
 margin-left: 8px;
`

const AsPill = styled.div`
  cursor: pointer;
  padding: 6px 10px;
  background-color: #394956;
  color: #FFF;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: width .4s;
  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    span.text {
      margin: 0 5px;
    }
  }
  
`

const UploadQueue = (props) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [langsSelected, setLangsSelected] = useState({})
  const [uploadToCancel, setUploadToCancel] = useState(null)
  const {uploads, update, defaultLanguage, cancelUploads, numberOfUploads, numberOfUploadsWaiting, openEditor} = useUpload()
  const [open, setOpen] = useState(false)
  const [expand, seExpand] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const {small = false} = props || {}

  const cancelAllUploads = () => {
    Object.values(uploads).forEach((upload) => upload.abortController && upload.abortController.abort())
    cancelUploads()
  }
  
  const cancelUpload = () => {
    if (uploadToCancel) cancelOneUpload(uploadToCancel) 
    else cancelAllUploads()
    setOpenConfirm(false)
  }

  const cancelOneUpload = (uploadId) => {
    const upload = uploads[uploadId]
    upload.abortController && upload.abortController.abort()
    setUploadToCancel(null)
  }

  const openSelector = (uploadId) => (event) => {
    setOpen(uploadId)
    setAnchorEl(event.currentTarget)
  }

  const close = () => {
    setOpen(false)
  }
  const handleListKeyDown = () => console.log

  useEffect(() => {
    if (uploadToCancel) setOpenConfirm(true)
  }, [uploadToCancel])

  const onCancel = () => {
    setOpenConfirm(false)
    setUploadToCancel(null)
  }

  const _handleSelectedLanguage = ({languageCode, label}) => {
    langsSelected[open] = {languageCode, label}
    setLangsSelected(langsSelected)
    update({id: open, languageCode})
  }

  const handleSelectedLanguage = _debounce(_handleSelectedLanguage, 500)

  const cancelMessage = uploadToCancel ? 'Are you sure you want to cancel this upload ?' : 'Are you sure you want to cancel all uploads?'
  if (numberOfUploads <= 0) return ''
  return (
    <RootCont show={true || numberOfUploads} small={!expand && small}>
      <FadeIn>
        {
          (small && !expand) ? 
            <AsPill onClick={() => seExpand(true)}>
              {
                numberOfUploadsWaiting ? 
                  <div><Chip label={numberOfUploadsWaiting} size="small" style={{background: '#FFF'}}/><span className='text'>Awaiting user input</span></div> :
                  <div><Chip label={numberOfUploads} size="small" style={{background: '#FFF'}}/><span className='text'>Uploading</span></div>
              }
            </AsPill>
            : 
            <Root>
              <Header>
                {
                  numberOfUploadsWaiting
                    ? <Text><WarningIcon /><Span>{numberOfUploadsWaiting} item awaiting input</Span></Text>
                    : <Text><ArrowIcon /><Span>Uploading {numberOfUploads} items</Span></Text>
                }
                <Text>
                  <CancelButton hover='none' onClick={() => setOpenConfirm(true)}>Cancel</CancelButton>
                </Text>
              </Header>
              <Content >
                {uploads.map(({id, viewForm, name, error, progress, complete, languageCode, started}) => {
                  const shortLanguageCode = (languageCode || '').split('-')[0]
              
                  return (
                    <SingleUpload key={id} selected={open === id} complete={(complete && languageCode) || langsSelected[id]} started={started}>
                      <Item onMouseOver={close} onClick={() => openEditor({id})} >
                        <DescriptionOutlinedIcon style={{margin: '-5px 7px 0 0', fill: '#37404A'}} />
                        <TextSpan onClick={viewForm}>{name}</TextSpan>
                      </Item>
                      <ClickAwayListener onClickAway={close}>
                        <Actions onMouseEnter={!complete ? openSelector(id) : () => {}} onClick={!complete && openSelector(id)}>
                          {
                            languageCode || langsSelected[id]
                              ? (
                                <LanguageNotice>
                                  <div style={{marginRight: '10px'}}>{languageCode ? LanguageIndexByCode[shortLanguageCode].label : langsSelected[id].label}</div>
                                  {
                                    error
                                      ? <Tooltip title={error}><StyledErrorIcon /></Tooltip>
                                      : <StyledProgressIcon value={progress * 100} size={15} />
                                  }
              
                                </LanguageNotice>
                              )
                              : (
                                <Fragment>
                                  {
                                    progress === 1
                                      ? <LanguageNotice >SELECT LANGUAGE</LanguageNotice>
                                      : <StyledProgressIcon value={progress * 100} size={15} />
                                  }
                                  <SelectLanguageIcon />
                                </Fragment>
                              )
                          }
                        </Actions>
                      </ClickAwayListener>
                    </SingleUpload>
                  )
                })}
              </Content>
            </Root>
            
        }
      </FadeIn>

      <Popper
        style={{zIndex: 55}}
        open={!!open}
        anchorEl={anchorEl}
        role={undefined}
        placement='right-end'
        transition
      >
        <Paper style={{transform: 'translateX(25px)', zIndex: 9999}}>
          <MenuList autoFocusItem={!!open} onKeyDown={handleListKeyDown} style={{maxHeight: '280px', overflow: 'scroll'}}>
            {
              LanguageLookup
                .map(({value: languageCode, label}) => (
                  <MenuItem selected={languageCode === defaultLanguage} 
                    onTouchEnd={evt => evt.preventDefault()}
                    onTouchStart={(evt) => {
                      console.log('touched', evt.target)
                      handleSelectedLanguage({languageCode, label})
                    }} 
                    onClick={(evt) => {
                      console.log('clicked', evt.target)
                      handleSelectedLanguage({languageCode, label})
                    }} 
                    key={languageCode}
                  >
                    {label}
                  </MenuItem>
                ))
            }
          </MenuList>
        </Paper>
      </Popper>

      <ConfirmDialog
        open={openConfirm}
        onCancel={onCancel}
        onClose={onCancel}
        onYes={cancelUpload}
        content={cancelMessage}
        yesText='Yes'
        noText='No'
      />
    </RootCont>

  )
}

export default UploadQueue
