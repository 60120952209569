import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {renderToString} from 'react-dom/server'
import {saveAs} from 'file-saver'
import htmlDocx from 'html-docx-js/dist/html-docx'
import juice from 'juice'
import Excel from 'exceljs'
import slug from 'slug'
import _forEach from 'lodash/forEach'

import store from 'store'

import {
  fetchSubtitles as fetchSubtitlesAction,
  generateSelectorForWordObjectsBySectionId,
  selectCollectionLanguage,
  selectCollectionName,
  selectCollectionText,
  selectGenerateAnnotations
} from 'features/collections'
import {selectCollectionHighlights, fetchHighlights} from 'features/highlights'
import {selectSpeakerIds, selectCollectionSpeakers, fetchSpeakers} from 'features/speakers'

import {fetchTranscript} from 'features/transcripts'

import {getTimeStamp} from 'components/common/Timer/convert-time'
import TimeStamp from 'components/common/EditorV2/HTMLEditor/Sections/TimeStamp'
import Word from 'components/common/EditorV2/HTMLEditor/Sections/Word'

const getShortCID = (cid) => cid.split('-').slice(0, 1).join('')

const useExporter = ({collectionId, canonicalRef}) => {
  const dispatch = useDispatch()
  const name = useSelector(state => selectCollectionName(state, collectionId))
  const language = useSelector(state => selectCollectionLanguage(state, collectionId))
  const [loading, setLoading] = useState(false)

  const fetchDetails = async () => {
    const {transcripts, speakers, highlights} = store.getState()
    if (transcripts.entities[collectionId] && speakers.entities[collectionId] && highlights.entities[collectionId]) return
    setLoading(true)
    try {
      await Promise.all([
        dispatch(fetchHighlights({collectionId})),
        dispatch(fetchSpeakers({collectionId})),
        dispatch(fetchTranscript({collectionId}))
      ])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSubtitles = async (format = 'srt') =>
    dispatch(fetchSubtitlesAction({id: collectionId, format}))

  const getDownloadName = (ext = 'txt', cid = '') => {
    const fileName = slug((name || '').replace('...', '')) || getShortCID(cid)
    return `${fileName}_${language}.${ext}`
  }

  const generateText = async () => {
    await fetchDetails()
    const state = store.getState()
    const collectionText = selectCollectionText(state, collectionId)
    const b = new Blob([collectionText], {type: 'text/plain;charset=utf-8'})
    saveAs(b, getDownloadName('txt'))
    return Promise.resolve()
  }

  const generateSubtitles = async (format = 'srt') => {
    await fetchDetails()
    const response = await fetchSubtitles(format) || {}
    console.log({response})
    const {payload: subtitles} = response
    const b = new Blob([subtitles], {type: `text/${format};charset=utf-8`})
    saveAs(b, getDownloadName(format))
  }

  const generateVttSubtitles = async () => {
    await fetchDetails()
    await generateSubtitles('vtt')
  }

  const downloadCsv = async (fileName, payload) => {
    await fetchDetails()
    let text = 'Start,End,Speaker,Annotation,Highlighted,Tags \n'
    _forEach(payload, highlight => {
      const {highlighted, content, startTime, endTime, speaker, tags = {}} = highlight
      if (highlighted) {
        const tagsArr = Object.keys(tags)
        const h = highlighted.replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ').replace(/ (\.|,|\?|!)$/, '$1').trim(' ')
        text += `"${getTimeStamp(startTime)}","${getTimeStamp(endTime)}","${speaker || ''}","${content}","${h}","${(tagsArr || []).join(', ')}" \n`
      }
    })
    const b = new Blob([text], {type: 'text/csv'})
    saveAs(b, fileName, {autoBom: true})
    return Promise.resolve()
  }

  const downloadXls = async (fileName, payload) => {
    await fetchDetails()
    var workbook = new Excel.Workbook()
    var worksheet = workbook.addWorksheet('Annotations')

    worksheet.columns = [
      {header: 'Start', key: 'start', width: 10},
      {header: 'End', key: 'end', width: 10},
      {header: 'Speaker', key: 'speaker', width: 15},
      {header: 'Annotation', key: 'annotation', width: 75},
      {header: 'Highlighted', key: 'highlighted', width: 75},
      {header: 'Tags', key: 'tags', width: 100}
    ]

    let dataRow = 2
    _forEach(payload, highlight => {
      const {highlighted, content, startTime, endTime, speaker, tags = {}} = highlight
      const tagsArr = Object.keys(tags)

      if (highlighted) {
        const h = highlighted.replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ').replace(/ (\.|,|\?|!)$/, '$1').trim(' ')
        worksheet.addRow({
          start: getTimeStamp(startTime),
          end: getTimeStamp(endTime),
          speaker: speaker || '',
          annotation: content,
          highlighted: h,
          tags: (tagsArr || []).join(', ')
        })

        worksheet.getCell(`D${dataRow}`).alignment = {wrapText: true}
        worksheet.getCell(`E${dataRow}`).alignment = {wrapText: true}
        dataRow++
      }
    })

    workbook.xlsx.writeBuffer().then(data => {
      const b = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      saveAs(b, fileName, {autoBom: true})
      return Promise.resolve()
    })
  }

  const generateAnnotations = async (isCsv) => {
    await fetchDetails()
    const state = store.getState()

    const annotations = selectGenerateAnnotations(state, collectionId)

    return isCsv
      ? downloadCsv(getDownloadName('csv'), annotations)
      : downloadXls(getDownloadName('xls'), annotations)
  }

  const generateDoc = async () => {
    await fetchDetails()
    const state = store.getState()
    const highlights = selectCollectionHighlights(state, collectionId)
    const highlightKeys = Object.keys(highlights) || []
    const speakerIds = selectSpeakerIds(state, collectionId)
    const speakers = selectCollectionSpeakers(state, collectionId)

    const commentsCSS = highlightKeys.map(hKey => {
      if (highlights[hKey] && highlights[hKey].content) {
        return `
          span[data-bookmark="${hKey}"]::before {
            content: " [${highlights[hKey].content}] ";
            color: #999;
            font-style: italic;
            background-color: #FFF;
            display: block;
            white-space:pre;
            font-size: 0.8em;
            width: 100%;
            text-align: center;
          }
          span[data-bookmark="${hKey}"] ~ span[data-bookmark="${hKey}"]::before {
            content: "";
          }
        `
      }
      return ''
    }).join('\n')

    const style = `
      <!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:Zoom>100</w:Zoom>w:DoNotOptimizeForBrowser/></w:WordDocument></xml><![endif]-->
      <style>
      body {
        font-family: 'Helvetica Neue';
      }
      h1.title {
        font-weight: normal;
      }
      table, tr, td {
        vertical-align: top;
        padding-bottom: 15px;
        font-family: 'Helvetica Neue';
      }
      td.left {
        padding-right: 14px;
        width: 150px;
        text-align: left;
        font-size: 13px;
      }
      table h1 {
        font-size: 14px;
        padding: 0;
        margin: 0;
      }

      td.right {
        text-align: left;
      }
      span[data-bookmark] {
        background-color: yellow;
      }

      ${commentsCSS}
      </style>
    `

    const html = `
      <!DOCTYPE html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          ${style}
        </head>
        <body>
          <h1 class='title'>${name}</h1>

          <div class="cont">
            <table>
              <tbody>
                ${
  speakerIds.map(id => {
    const getWords = generateSelectorForWordObjectsBySectionId()
    const words = getWords(state, collectionId, id)
    return renderToString(
      <tr>
        <td class='left'>
          <TimeStamp time={id} />
          {speakers[id]?.name || ''}
        </td>

        <td>
          {words.map((wordObject, index) => (
            <Word
              key={wordObject.start_time}
              isUnderlined={false}
              isFirst={index === 0}
              wordObject={wordObject}
            />
          ))}
        </td>
      </tr>
    )
  })
}
              <tbody>
            </table>
          </div>
        </body>
      </html>
    `

    const juiced = juice(html, {inlinePseudoElements: true})
    const blob = htmlDocx.asBlob(juiced)
    const fileName = getDownloadName('docx')
    saveAs(blob, fileName)
  }

  return {
    loading,
    generateAnnotations,
    generateDoc,
    generateSubtitles,
    generateVttSubtitles,
    generateText
  }
}

export default useExporter
