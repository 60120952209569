import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  // current,
  createSlice
} from '@reduxjs/toolkit'

import _findIndex from 'lodash/findIndex'
import _head from 'lodash/first'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

import {
  fetchTranscript as fetchTranscriptApi,
  updateTranscript as updateTranscriptApi
} from 'api/transcripts'

import {
  checkout
} from 'features/collections'

import {toast} from 'components/common/Toaster'

// Slice
export const transcriptssAdapter = createEntityAdapter()
const initialState = transcriptssAdapter.getInitialState({
  pendingRequests: []
})

// Selectors
const sliceSelector = state => state.transcripts
const {selectById} = transcriptssAdapter.getSelectors(sliceSelector)

export const selectCollectionTranscript = createSelector(
  [selectById],
  transcript => _omit(transcript, 'id')
)

export const selectCollectionWords = createSelector(
  [selectCollectionTranscript],
  ({words} = {}) => words || []
)

export const selectCollectionFirstWord = createSelector(
  [selectCollectionWords],
  _head
)

export const selectCollectionWordStartTimes = createSelector(
  [selectCollectionWords],
  words => words.map(word => word.start_time)
)

// Actions
export const fetchTranscript = createAsyncThunk(
  'transcripts/fetchOne',
  async ({collectionId, locale, trimmed}) => {
    try {
      const {data} = await fetchTranscriptApi({collectionId, locale})
      data.id = collectionId
      if (trimmed) {
        const bookmark = 1
        const type = 'pronunciation'
        const confidence = 1
        const lastTime = data.words[data.words.length - 1].end_time + 1
        const speaker = data.words[data.words.length - 1].speaker
        const skip = false
        data.words.push({skip, bookmark, 'start_time':lastTime, 'end_time':lastTime, 'alternatives':[{confidence,'content':'[CONTENT', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 1, 'end_time':lastTime + 1, 'alternatives':[{confidence,'content':'TRIMMED', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 2, 'end_time':lastTime + 2, 'alternatives':[{confidence,'content':'-', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 3, 'end_time':lastTime + 3, 'alternatives':[{confidence,'content':'UPGRADE', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 4, 'end_time':lastTime + 4, 'alternatives':[{confidence,'content':'REQUIRED]', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 5, 'end_time':lastTime + 5, 'alternatives':[{confidence,'content':'', speaker}],type})
      }
      return data
    } catch (error) {
      console.log('unable to fetch transcripts', collectionId, error)
    }
  }
)

export const updateTranscript = createAsyncThunk(
  'transcripts/updateOne',
  async ({id, words, startTime, endTime, locale}, {getState, dispatch}) => {
    try {
      const state = getState()
      dispatch(checkout({collectionId: id, uid: state.firebase.auth.uid}))
      await updateTranscriptApi({collectionId: id, data: {words, startTime, endTime}, locale})
    } catch (error) {
      toast.error('Issue saving updates.')
      console.log(error)
    }
  }
)

export const slice = createSlice({
  name: 'transcripts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests.push(requestId)
    }

    const removeRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests = state.pendingRequests.filter(id => id !== requestId)
    }

    builder
      .addCase(updateTranscript.fulfilled, removeRequestId)
      .addCase(updateTranscript.rejected, removeRequestId)

    builder
      .addCase(fetchTranscript.fulfilled, (state, {meta, payload}) => {
        transcriptssAdapter.upsertOne(state, payload)
      })
      .addCase(updateTranscript.pending, (
        state,
        args
      ) => {
        const {meta: {arg: {id, words, startTime, endTime}}} = args
        const oldWords = selectCollectionWords({transcripts: state}, id)
        const preSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= startTime)
        const preSlice = oldWords.slice(0, preSliceIndex)
        const postSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= endTime)
        const postSlice = oldWords.slice(postSliceIndex)
        _set(state, ['entities', id, 'words'], [...preSlice, ...words, ...postSlice])
        addRequestId(state, args)
      })
      .addCase('collections/fetch/fulfilled', (state, {meta, payload}) => {
        const {arg: {id: collectionId, versionId} = {}} = meta

        if (!versionId) return

        const {items} = payload
        _set(state, ['entities', collectionId, 'words'], items)
      })
  }
})

const reducer = slice.reducer
export default reducer
