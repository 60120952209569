import React from 'react'
import styled from 'styled-components'
import Progress from 'components/common/CreatorDialog/UploadProgress'
import DetailsForm from 'components/common/CreatorDialog/DetailsFormV2'
import useUpload from 'hooks/useUpload'
import Loading from 'components/common/Loading'

const UploadingCont = styled.div`
  width: 150px;
`

const DetailsFormCont = styled.div`
  width: 440px;
`

const Split = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UploadProgress = (props) => {
  const {editingUpload} = useUpload()
  if (!editingUpload) return <Loading />
  return (
    <Split>
      <UploadingCont>
        <Progress value={editingUpload.progress * 100} />
      </UploadingCont>
      <DetailsFormCont>
        <DetailsForm />
      </DetailsFormCont>
    </Split>
  )
}

export default UploadProgress
