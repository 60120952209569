import React from 'react'

const ArrowIcon = (props) => {
  const {color = '#7DBB91'} = props
  return (
    <svg width='9' height='11' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line x1='4.50007' y1='2.00007' x2='4.50007' y2='11.0001' stroke={color} strokeWidth='2.57143' strokeLinecap='round' />
      <line x1='3.85693' y1='2.53263' x2='1.81807' y2='4.5715' stroke={color} strokeWidth='2.57143' strokeLinecap='round' />
      <line x1='7.18173' y1='4.57153' x2='5.14286' y2='2.53267' stroke={color} strokeWidth='2.57143' strokeLinecap='round' />
    </svg>
  )
}

export default ArrowIcon
