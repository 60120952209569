import React from 'react'
import styled from 'styled-components'

import DetailsForm from 'components/common/CreatorDialog/DetailsFormV2'

const DetailsFormCont = styled.div`
  width: 440px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const UploadProgress = (props) => {
  
  return (
    <DetailsFormCont>
      <DetailsForm isUrlForm />
    </DetailsFormCont>
  )
}

export default UploadProgress
