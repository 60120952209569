import React from 'react'
import {Link} from 'react-router-dom'
import NotificationIcon from '@material-ui/icons/NotificationsRounded'
import styled from 'styled-components'
import useProfile from 'hooks/useProfile'

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: -2px 10px 0 0;
`

const Text = styled.div`
  opacity: 1;
  font-size: 14px;
  white-space: nowrap;
  flex: 1;
  text-align: left;
  margin-left: 4px;
  transition: opacity 1s 3.7s, width 1s, height .3s;
`

const NoticeCont = styled.div`
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  padding: 8px 10px 8px 8px;
  background-color: #f14877;
  border-radius: 5px;
  color: #FFF;
  transform: translateY(-100px);
  ${({show}) => show && `
    transform: translateY(0px);
  `}
  :hover {
    background-color: #FFF;
    color: #777;
    transition: background-color .5s, color .5s;
  }
  :active {
    box-shadow: none;
    transform: scale(.98);
    transition: transform .1s;
  }
  transition: transform .1s, background-color .5s, color .5s;
`

export default ({history}) => {
  const {invites} = useProfile()

  const inviteCount = invites ? invites.length : 0
  const hasInvites = inviteCount > 0

  return (
    <Root>
      <Link to={'/settings'}>
        <NoticeCont show={hasInvites} >
          <NotificationIcon fontSize='small' />
          <Text>
            {inviteCount} pending invite{inviteCount > 1 ? 's' : ''}
          </Text>
        </NoticeCont>
      </Link>
    </Root>
  )
}
