import {useSelector} from 'react-redux'
import {useFirestoreConnect} from 'react-redux-firebase'

export default ({uid}) => {
  // console.log('use users', uid)
  useFirestoreConnect([{collection: 'Users', doc: uid}])
  const user = useSelector(({firestore: {data}}) => data.Users && data.Users[uid])
  return {
    user
  }
}
