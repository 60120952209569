import React, {Component} from 'react'
// import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
// // import {GA_TRACKING_ID, FB_TRACKING_ID} from 'utils/env'
import {FB_TRACKING_ID} from 'utils/env'
// import {currentEnv, SEGMENT_KEY} from 'utils/env'
import {currentEnv} from 'utils/env'
import {firebaseAuth} from 'utils/Firebase'
import {track} from 'api/user'

let FacebookAnalytics = ReactPixel
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
}
const advancedMatching = {} // { em: 'some@email.com' };
FacebookAnalytics.init(FB_TRACKING_ID, advancedMatching, options)

// let GoogleAnalytics = ReactGA
// GoogleAnalytics.initialize(GA_TRACKING_ID)

// client segment
// window.analytics._writeKey = SEGMENT_KEY 
// window.analytics.load(SEGMENT_KEY)

// const useClientSegment = false
const regexUUID = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

const getEmailDomain = () => {
  let email = firebaseAuth()?.currentUser?.email || ''
  if (!email) email = localStorage.getItem('konchemail', 'sean@konch.ai') || ''
  return email.split('@')[1]
}

const _standardEvent = (event, data) => {
  let standardEvent
  switch (event){
  case 'UserCreated':
    standardEvent = FacebookAnalytics.track('CompleteRegistration', data)
    break
  case 'UserClickedPaymentOption':
    standardEvent = FacebookAnalytics.track('InitiateCheckout', data)
    break
  case 'Payment':
    standardEvent = FacebookAnalytics.track('Purchase', data)
    break
  case '/':
  case '/signin':
  case '/signin/':
  case '/register':
  case '/register/':
  case '/plans':
  case '/plans/':
    standardEvent = FacebookAnalytics.track('ViewContent', {...data, page: event})
    break
  // TODO
  case '4':
    standardEvent = FacebookAnalytics.track('StartTrial', data)
    break
  case '5':
    standardEvent = FacebookAnalytics.track('Subscribe', data)
    break
  case '6':
    standardEvent = FacebookAnalytics.track('AddPaymentInfo', data)
    break
  default:
    // no standard event for this event
    standardEvent = Promise.resolve()
  }
  return standardEvent
}

export const trackCustom = ({category, action, data}) => {
  const event = (category + action).replace(/\s/, '')
  const label = JSON.stringify(data || {}).slice(0, 499)
  const emailDomain = getEmailDomain()
  console.log('track custom event', {event, category, emailDomain, action, currentEnv, label})

  // Facebook    // Server side segment does not allow FB Pixel
  return Promise.all([
    track({event, properties: {category, action, ...data}}),
    _standardEvent(event, data),
    FacebookAnalytics.trackCustom(event, data)
  ])

  // useClientSegment && window.analytics?.track && window.analytics.track(event, data)
  // GoogleAnalytics.event({category, action, label})
}

export const trackIdentity = ({uid}) => {
  const emailDomain = getEmailDomain()
  console.log('track identity', {uid, emailDomain})
  // server side segment
  return track({action: 'identify', uid, traits: {emailDomain}})  

  // useClientSegment && window.analytics?.track && window.analytics.identify(uid, {emailDomain})
  // GoogleAnalytics.event({category, action, label})
}

export default function withTracker (WrappedComponent, params, options = {}) {

  const trackPage = (page) => {
    let pageName = page.replace(regexUUID, '[id]')
    const emailDomain = getEmailDomain()
    console.log('page tracker', pageName, emailDomain) //, FB_TRACKING_ID, GA_TRACKING_ID)
    const {referrer, title} = document
    const {href: url, pathname: path, host, search: query,} = window.location
    const properties = {
      referrer, 
      title,
      url, 
      emailDomain,
      path, 
      host, 
      query
    }

    // Facebook           //server segment
    return Promise.all([
      track({action: 'page', name: pageName, properties}),
      _standardEvent(pageName),
      FacebookAnalytics.pageView(page)
    ])

    // no longer used in lieu of segment
    //  useClientSegment && window.analytics?.page && window.analytics.page(pageName, {currentEnv, emailDomain})
    // GoogleAnalytics.pageview(page)  // Google
    // trackCustom({category: 'Page', action: 'Changed', data: page})
  }

  const HOC = class extends Component {
    componentDidMount () {
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentDidUpdate (prevProps) {
      const currentPage = prevProps.location.pathname
      const nextPage = this.props.location.pathname
      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} {...params} />
    }
  }

  return HOC
}
