import React, {useState, createContext} from 'react'

export const UnderlineContext = createContext()

export const UnderlineProvider = ({children}) => {
  const [underlineId, setUnderlineId] = useState(null)
  const [searchUnderlineIds, setSearchUnderlineIds] = useState(null)
  const [underlineSpeaker, setUnderlineSpeaker] = useState(null)

  return (
    <UnderlineContext.Provider
      value={
        [
          [underlineId, setUnderlineId],
          [searchUnderlineIds, setSearchUnderlineIds],
          [underlineSpeaker, setUnderlineSpeaker]
        ]
      }
    >
      {children}
    </UnderlineContext.Provider>
  )
}
