import React, {useState, createContext} from 'react'

export const LocalDirtyContext = createContext()

export const LocalDirtyProvider = ({children}) => {
  const [localDirtyBlocks, setLocalDirtyBlocks] = useState([])

  return (
    <LocalDirtyContext.Provider value={[localDirtyBlocks, setLocalDirtyBlocks]}>
      {children}
    </LocalDirtyContext.Provider>
  )
}
