import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import uuid from 'uuid/v4'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle as faClose} from '@fortawesome/free-solid-svg-icons'
import Dialog from 'components/common/Dialog'
import Loading from 'components/common/Loading'
import FileUploader from './FileUploader'
import UploadProgress from './UploadProgress'
import PasteDialog from './PasteDialog'
import ScreenRecorder from './ScreenRecorder'
import LinkUploader from './LinkUploader'
import Recorder from './Recorder'
import useUpload from 'hooks/useUpload'
import useProfile from 'hooks/useProfile'
import usePlan from 'hooks/usePlan'
import useDictionary from 'hooks/useDictionary'
import {trackCustom} from 'utils/Tracker'

const Root = styled(Dialog)`
  z-index: 999;
`

const Close = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  top: 42px;
  left: 42px;
  z-index: 2000;
  color: #777;
  &:hover {
    color: #EEE;
  }
  transition: color 1s;
`

const Cont = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  @media (max-height: 300px) {
    height: 50%;
  }
`

const RootCont = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-height: 300px) {
    height: 50%;
  }
`

const DropZone = () => {
  const {oid, uid, billing} = useProfile()
  const {customer, paymentMethod, subscription, plan, enterprise} = billing || {}
  const hasBillingAccount = customer && (subscription || plan)
  const hasPaymentMethod = paymentMethod
  const isEnterprise = !!enterprise
  const [reloading, setReLoading] = useState(false)
  const [closing, setClosing] = useState(false)

  const {usage, fetchUsage, loading, fetchCredits, credits} = usePlan({oid, uid, reloading})

  const {fetchDictionaries} = useDictionary({uid})
  const {isOpen, closeDialog, openDialog, upload, openEditor, isEdit, showOverLimit} = useUpload()

  const onClose = () => closeDialog()
  //reset closing to false, so next time we open recorder, its false.
  useEffect(() => {
    closing && setClosing(false)
  },[closing])

  const onCloseUser = () => {
    //set closing to true so recorder stops the initial preview stream and stops recording.
    setClosing(true)
    trackCustom({category: 'User', action: 'Closed Uploader', data: {}})
    return closeDialog()
  }
  
  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const closeOnEscape = (evt) => evt.keyCode === 27 && onCloseUser()

  const hasMinutes = async (tries = 0) => {
    console.log('has minutes?', {billing, hasBillingAccount, usage, credits})
    if (!hasBillingAccount && !hasPaymentMethod && !isEnterprise) {
      setReLoading(true)
      const c = await fetchCredits()
      setReLoading(false)
      console.log('check agaib', c)
      if (!c) {
        closeDialog()
        showOverLimit(true)
        return false
      } else return true
    }
    if (hasPaymentMethod || isEnterprise) {
      // minumum they can use PAYG or already on a plan so usage data is correct (not a new plan)
      return true
    }

    console.log({loading, hasMins: usage.hasMinutes, tries})
    if (!usage.hasMinutes) {
      if (tries > 2) {
        console.log('Tried ', tries, ' times')
        showOverLimit(true)
        closeDialog()
        return false
      } else if (tries <= 2) {
        setReLoading(tries === 0 ? 'Verifying Minutes' : tries === 1 ? 'Awaiting minutes...' : `Didn't find minutes. Trying once more...`)
        // check credits once
        if (tries === 0) {
          const c = await fetchCredits()
          if (c) {
            setReLoading(false)
            closeDialog()
            return true
          }
        }

        await delay(2800)
        const data = await fetchUsage()
        console.log({data})
        setReLoading(false)
        if (data.hasMinutes) return true
        return hasMinutes(tries + 1)
      }
      return false
    }
    return true
  }

  useEffect(() => {
    // console.log('usage', {oid, uid})
    if (oid && uid && hasBillingAccount) {
      fetchDictionaries()
      fetchUsage()
    }
  }, [oid])

  useEffect(() => {
    window.addEventListener('keydown', closeOnEscape)
    return () => window.removeEventListener('keydown', closeOnEscape)
  }, [])

  return !!isOpen && (
    <Root >
      <Close icon={faClose} size={'2x'} onClick={onCloseUser}>Close</Close>
      <RootCont>
        {
          (loading || reloading || !oid ) ? <Loading textColor='#FFF' message={reloading || ''} /> : (
            <Cont>
              {
                isOpen === 'file-drop' && !isEdit &&
                <FileUploader
                  onUpload={async (videoBlobs) => {
                    const hasMins = await hasMinutes()
                    if (!hasMins) return
                    if (videoBlobs.length === 1) {
                      const id = uuid()
                      openEditor({id})
                      const blob = videoBlobs[0]
                      upload({id, blob})
                      return
                    }
                    await onClose()
                    for (let blob of videoBlobs) upload({blob, id: uuid()})
                  }}
                  showRecorder={() => openDialog({view: 'recorder'})} />
              }
              {isEdit && <UploadProgress />}
              {isOpen === 'kaltura' &&  <PasteDialog  />}
              {
                isOpen === 'recorder' &&
                <Recorder closing={closing} onUpload={async (blob) => {
                  const hasMins = await hasMinutes()
                  if (hasMins) await upload({blob, id: uuid()})
                  await onClose()
                }} showFileUploader={() => openDialog({view: 'file-drop'})} />
              }
              {(isOpen === 'link' || isOpen === 'dropbox') &&  <LinkUploader  />}
              {
                isOpen === 'screen-recorder' &&
                <ScreenRecorder closing={closing} onUpload={async (blob) => {
                  await onClose()
                  const hasMins = await hasMinutes()
                  if (hasMins) await upload({blob, id: uuid()})
                }}/>
              }
              {isOpen === 'link' &&  <LinkUploader  />}
            </Cont>

          )
        }
        
      </RootCont>
    </Root>
  )
}

export default DropZone
