import React, {useEffect, useState} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const CA = 'ca-ES' // Catalan""
const DE = 'de-DE' // German
const DA = 'da-DK' // Danish
const EN = 'en-US' // English
const ES = 'es-ES' // Spanish
const FR = 'fr-FR' // French
const HI = 'hi-IN' // Hindi
const IT = 'it-IT' // Italian
const JA = 'ja-JP' // Japanese
const KO = 'ko-KR' // Korean
const NL = 'nl-Nl' // Ducth
const PL = 'pl-PL' // Polish
const PT = 'pt-PT' // Portuguese
const RU = 'ru-RU ' // Russian
const SV = 'sv-SE' // Swedish
// v2
const AR = 'ar' // Arabic
const BG = 'bg' // Bulgarian
const HR = 'hr' // Croation
const CS = 'cs' // Czech
const FI = 'fi' // Finnish
const EL = 'el' // Greek
const HU = 'hu' // Hungarian
const LV = 'lv' // Latvian
const MS = 'ms' // Malay
const MN = 'cmn' // Mandarin
const NO = 'no' // Norwegian
const RO = 'ro' // Romanian
const SK = 'sk' // Slovak
const SL = 'sl' // Slovenian
const TR = 'tr' // Turkish

export const LanguageLookup = [
  {value: EN, label: 'English', precision: true},
  {value: DA, label: 'Danish', precision: true},
  {value: ES, label: 'Spanish'},
  {value: PT, label: 'Portuguese'},
  {value: NL, label: 'Dutch'},
  {value: DE, label: 'German'},
  {value: FR, label: 'French'},
  {value: JA, label: 'Japanese'},
  {value: KO, label: 'Korean'},
  {value: IT, label: 'Italian'},
  {value: PL, label: 'Polish'},
  {value: CA, label: 'Catalan'},
  {value: HI, label: 'Hindi'},
  {value: RU, label: 'Russian'},
  {value: SV, label: 'Swedish'}, // precision: true
  {value: AR, label: 'Arabic'},
  // v2
  {value: BG, label: 'Bulgarian'},
  {value: HR, label: 'Croation'},
  {value: CS, label: 'Czech'},
  {value: FI, label: 'Finnish'}, // precision: true
  {value: EL, label: 'Greek'},
  {value: HU, label: 'Hungarian'},
  {value: LV, label: 'Latvian'},
  {value: MS, label: 'Malay'},
  {value: MN, label: 'Mandarin'},
  {value: NO, label: 'Norwegian'}, // precision: true
  {value: RO, label: 'Romanian'},
  {value: SK, label: 'Slovak'},
  {value: SL, label: 'Slovenian'},
  {value: TR, label: 'Turkish'}
]

export const LanguageIndexByCode = LanguageLookup.reduce((acc, lang) => {
  acc[lang.value.split('-')[0]] = {...lang}
  return acc
}, {})

export const LanguageIndexByLabel = LanguageLookup.reduce((acc, lang) => {
  acc[lang.label] = lang.value
  return acc
}, {})

export const isPrecisionEnabled = ({languageCode}) => (LanguageIndexByCode[(languageCode || '').split('-')[0]] || {}).precision

const SelectLanguageCont = styled.div`
  color: rgba(250, 250, 250, 0.4);
  margin-bottom: 10px;
  & .select__menu {
    color: #333;
  }
`

const LanguageDefault = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 0;
  padding: 4px 0;
  opacity: 0.2;
  &:hover {
    opacity: 1;
    transition: opacity .7s;
    transition-delay: .3s;
  }  
  transition: opacity 1s;
  transition-delay: 2s;
  ${props => props.hide && 'visibility: hidden;'}



  .MuiSwitch-root {
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: lightgreen;
    } 
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb {
      background-color: #EEE;
    } 
    .MuiSwitch-thumb {
      background-color: #CCC;
    }
    .MuiSwitch-track {
      background-color: #333;
    }
  }
  
`

const SelectFormControl = styled(FormControl)`
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #CCC;
    .MuiSelect-root {
      padding: 9px;
    }
    ${({value}) => value === 'none' && `
      .MuiSelect-select {
        color: #777;
      }
    `}
    
    svg {
      margin-right: 8px;
      padding-left: 4px;
      border-left: 1px solid #CCC;
    }
    &:before, &:after {
      content: none !important;
    }
`

const MUIAutoComplete = styled(Autocomplete)`
  button {
    display: none;
  }
`

const MUIAutoCompleteGlobalStyle = createGlobalStyle`
  .language-select-listBox {
    max-height: 208px !important;
    margin-top: -50px;
  }
`

export const SimpleSelect = (props) => {
  const {
    variant,
    defaultLanguage,
    onChange,
    emptyLabel = 'Select a Language'
  } = props
  const [languages, setLanguages] = useState([...LanguageLookup.sort((a, b) => a.label.localeCompare(b.label))])
  const [language, setLanguage] = React.useState('')
  const value = language || defaultLanguage || ''
  const getLangLabel = (langValue) => languages.find(lang => lang.value === langValue)?.label || ''
  const [autCompleteVal, setAutCompleteVal] = useState(value ? {
    value,
    label: `${getLangLabel(value)}${defaultLanguage ? ' (Default)' : ''}`
  } : null)
  const [languageInputVal, setLanguageInputVal] = useState('')
  const [hasDefaultLangOption, setHasDefaultLangOption] = useState(false)

  const handleChange = (selectedValue) => {
    const language = selectedValue?.value
    setLanguage(language)
    setAutCompleteVal(selectedValue)
    onChange && onChange(language)
  }

  useEffect(() => {
    setLanguage(value)
  }, [])

  useEffect(() => {
    if (defaultLanguage) {
      if (hasDefaultLangOption) {
        languages[0] = {
          label: `${getLangLabel(defaultLanguage)} (Default)`,
          value: defaultLanguage
        }
      } else {
        languages.unshift({
          label: `${getLangLabel(defaultLanguage)} (Default)`,
          value: defaultLanguage
        })
      }
      setHasDefaultLangOption(true)
      setLanguages(languages)
      setAutCompleteVal(languages[0])
      return
    }

    if (hasDefaultLangOption) {
      languages.shift()
      setLanguages(languages)
      setLanguage(language)
      setAutCompleteVal({
        label: getLangLabel(language),
        value: language
      })
      setHasDefaultLangOption(false)
    }
  }, [defaultLanguage])

  return (
    <SelectFormControl variant={variant} value={value}>
      <MUIAutoComplete
        options={languages}
        value={autCompleteVal}
        onChange={(_, selectedLanguage) => handleChange(selectedLanguage)}
        inputValue={languageInputVal}
        onInputChange={(_, newInputValue) => setLanguageInputVal(newInputValue)}
        onClose={e => {
          if (e.key === 'Escape') {
            e.target.blur()
            handleChange(languages.find(l => l.value === value))
          }
        }}
        getOptionLabel={(val) => val.label}
        getOptionSelected={(option, value) => option.value === value.value}
        classes={{
          listbox: 'language-select-listBox'
        }}
        renderInput={(params) => 
          <TextField
            {...params}
            placeholder={emptyLabel}
            variant="outlined"
          />
        }
      /> 
    </SelectFormControl>
  )
}

export default ({onSelectLanguage, onSetLanguageDefault, defaultLanguage, languageCode}) => {
  return (
    <>
      <MUIAutoCompleteGlobalStyle/>
      <SelectLanguageCont>
        <SimpleSelect
          displayEmpty
          defaultLanguage={defaultLanguage}
          onChange={selectedLanguage => {
            onSelectLanguage && onSelectLanguage(selectedLanguage)
          }}
        />
        <LanguageDefault hide={!languageCode}>
          <span>Set language as default</span>
          <Switch
            value={defaultLanguage || ''}
            checked={!!defaultLanguage && defaultLanguage === languageCode}
            onChange={(evt, on) => onSetLanguageDefault(on ? languageCode : null)}
          />
        </LanguageDefault>
      </SelectLanguageCont>
    </>
  )
}
