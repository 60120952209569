import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ContentEditable from 'react-contenteditable'
import {useInterval} from 'react-interval-hook'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faUser, faEllipsisH, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

import {toast} from 'components/common/Toaster'
import {PartialChip, PausedChip} from 'components/common/Dashboardv2/Chip'
import Avatar from 'components/common/Avatar'
import TranscriptionProgress from 'components/common/CreatorDialog/TranscriptionProgress'
import CollectionMenu from 'components/common/Popover/CollectionMenu'
import Spinner from 'components/common/Spinner'
import HumanCheckedIcon from 'components/common/HumanCheckedIcon'
import useUsers from 'hooks/useUsers'
import useProfile from 'hooks/useProfile'
import useCollection from 'hooks/useCollection'
import LightTooltip from 'components/common/LightTooltip'
import EditorContexts from 'context/EditorContexts'

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const TimeLabel = styled.div`
  display: flex;
  color: #FFF;
  font-size: 9px;
  justify-content: space-between;
  transition: color 1s;
  padding: 3px 0;
`

const RootCont = styled.div`
  &:hover ${TimeLabel} {
    color: #CCC;
    transition: color .5s;
    transition-delay: .5s;
  }
`

const Root = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 11px;
  margin-bottom: 22px;
  box-shadow: 0 2px 3px rgba(0,0,0,.05);
  background: white;
  border-radius: 6px;
  padding: 16px 21px 22px 21px;
  cursor: pointer;
  transition: all ease 200ms;
  box-sizing: border-box;
  height: 250px;
  width: 200px;
  :hover {
    transform: scale(1.06);
    box-shadow: 0 2px 15px rgba(0,0,0,.05);
  }

  ${props => props.deleting && `
    transition: opacity 4s, transform 5s;
    opacity: 0.1 !important;
    transform: scale(.10) !important;
  `}

  ${props => props.isError && `
    color: rgba(0, 0, 0, 0.4);
  `}
`

const LockedIndicator = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  background: #ECF0F4;
  left: calc(50% - 20px);
  top: -13px;
  border-radius: 100%;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  box-shadow: inset 0px -1px 1px rgba(50,50,50,.07);
`

// const NoData = styled.span`
//   &:before {
//     color: #CCC;
//     font-style: italics;  
//     content: 'No Data';
//   }
// `

const Body = styled.div`
  font-size: 14px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NameField = styled(ContentEditable)`
  color: #37404A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  outline: none;
  ${props => !props.disabled && 'background-color: #EFEFEF;'}
  overflow: hidden;
  max-height: 96px;
  max-width: 170px;
`

const Text = styled.div`
  color: #898989;
  font-size: 12px;
  line-height: 18px;
  font-family: monospace;
  margin-top: 4px;
  width: 104%;
`

const Accuracy = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: #7DBB91;
  letter-spacing: 1.29px;
  line-height: 30px;
  flex: 1;
`

const AccuracyData = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`
const HumanIcon = styled.div`
  margin-left: -6px;
  margin-bottom: -6px;
  margin-right: 10px;
  height: 36px;
  & ${Spinner} {
    left: 21px;
    position: absolute;
    top: -7px;
  }
`

const AIChecked = styled.div`
  margin: 0 12px 0 0;
  font-size: 1.2em;
  & ${Spinner} {
    position: absolute;
    left: -6px;
    top: 1px;
  }
  & svg {
    margin-right: 6px;
  }
`

const ErrorIcon = styled(FontAwesomeIcon)`
  margin: 0 12px 0 0;
  font-size: 1.3em;
  color: red;
`

const CustomLink = styled(Link)`
  flex: 1;
  overflow: hidden;
  margin-bottom: 8px;
  text-decoration: none;
`

const OwnerLock = ({uid, isChangingOwner}) => {
  const {user} = useUsers({uid})
  if (isChangingOwner) return <LockedIndicator><Spinner size={20} /></LockedIndicator>
  return <LockedIndicator>{user ? <Avatar profile={user} src={user.avatar} small size={22} /> : <FontAwesomeIcon icon={faUser} />}</LockedIndicator>
}

export const Status = ({isError, collection, shouldBeDoneButIsNot, dontShowProgress}) => {
  
  if (isError) return <ErrorIcon icon={faExclamationTriangle} />
  const isProcessing = collection.tProgress < 100
  const {tCompletion = {}, props, transcriptionStarted, paused, trimmed} = (collection || {})
  const {duration} = props || {}

  // default status
  let status = <AIChecked><FontAwesomeIcon icon={faCheck} /></AIChecked>
  
  if (tCompletion.perfection) status = <HumanIcon><HumanCheckedIcon /></HumanIcon>
  else if (paused) status = <PausedChip />
  else if (tCompletion.draft) {
    if (trimmed) status = <PartialChip />
    else status = (
      <AIChecked>
        <FontAwesomeIcon icon={faCheck} />
        {
          tCompletion.perfection === false &&
          <div style={{position: 'relative', top: '-31px', opacity: 0.5}}>
            <Spinner small size={27} defaultColor={'#7dbb91'} thickness={0} secondThickness={1} />
          </div>
        }
      </AIChecked>
    )
  } else if (!dontShowProgress && duration && transcriptionStarted) status = <TranscriptionProgress bar started={transcriptionStarted} duration={duration} disabled={paused} />
  else if (isProcessing && !shouldBeDoneButIsNot) status = <Spinner small color='#7DBB91' style={{marginRight: '10px'}} />
  else if (shouldBeDoneButIsNot) status = ''

  return <Accuracy><AccuracyData>{status}</AccuracyData></Accuracy>
}

export const Name = ({id, name, nameIsEditable, onBlur, fullWidth}) => {
  const {renameCollection} = useCollection({id})

  const handleNameClick = evt => {
    if (nameIsEditable) {
      evt.stopPropagation()
      evt.preventDefault()
    }
  }

  const handleNameBlur = evt => {
    console.log(evt.target.textContent)
    renameCollection(evt.target.textContent)
    onBlur(evt.target.textContent, evt)
  }

  const handleNameKeyDown = evt => {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      evt.stopPropagation()
      evt.target.blur()
    }
  }

  return (
    nameIsEditable
      ? (
        <NameField
          onBlur={handleNameBlur}
          onKeyDown={handleNameKeyDown}
          html={name ? name.slice(0, 35) : 'No Name'}
          onClick={evt => handleNameClick(evt)} />
      )
      : <NameField html={name ? name.slice(0, 35) : 'No Name'} disabled />
  )
}

export const Snippet = ({cid, snippet: text, message, style}) => {
  let snippet = text ? text.replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ').replace(/ (\.|,|\?|!)$/, '$1').slice(0, 250) : text === undefined ? '' : '[No Data]'
  if (snippet.length > 50) snippet = snippet.slice(0, snippet.length - 3).trim() + '...'
  if (!snippet) snippet = message
  return <Text style={style}>{snippet}</Text>
}

export default ({id, usage}) => {
  const {
    deleteCollection,
    fetchCollection,
    makeOwner,
    message,
    collection,
    isProcessing,
    startedString,
    shouldBeDoneButIsNot,
    expectedTimeLeft,
    isRetried
  } = useCollection({id})
  const {uid} = useProfile()
  const [name, setName] = useState(collection.name ? collection.name : '-')
  const [copyId, setCopyId] = useState(collection.name ? collection.name : '-')
  const [nameIsEditable, setNameIsEditable] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isChangingOwner, setChangingOwner] = useState(false)
  const {snippet, isError, tProgress, started, props = {}} = collection

  const isOwner = collection.userId === uid
  const href = `/edit-v2/${collection.id}/`

  const {duration} = props

  // either wait for initial collection to load
  //   or wait for time left to hit 1/2 duration
  //   or keep pinging every 10 seconds
  const waitFor = !duration ? 5000 : expectedTimeLeft > 0 ? expectedTimeLeft * 1000 : 10000

  const handleNameBlur = name => {
    setNameIsEditable(false)
    setName(name)
  }

  const handleDelete = evt => {
    setIsDeleting(true)
    deleteCollection()
  }

  const handleEditName = () => setNameIsEditable(true)

  const handleMakeOwner = async () => {
    setChangingOwner(true)
    await makeOwner(uid)
    setChangingOwner(false)
  }

  const {start, stop, isActive} = useInterval(
    () => {
      console.log('Checking', collection.id)
      fetchCollection()
    },
    waitFor,
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: true,
      onFinish: () => {
        // console.log('Callback when timer is stopped')
        if (!snippet) fetchCollection()
      }
    }
  )

  const handleOnRetryComplete = () => {
    console.log('handleOnRetryComplete', isActive(), {isProcessing, tProgress, isRetried})
    !isActive() && start()
  }

  useEffect(() => {
    // console.log('tProgress changed', id, {isProcessing, tProgress})
    isProcessing && start()
    tProgress !== undefined && tProgress === 100 && isActive() && stop()
  }, [tProgress])

  // tProgress < 100 && console.log({waitFor, isActive: isActive(), isProcessing, tProgress})

  return (
    <EditorContexts collectionId={collection.id} isCorrector={false}>
      <RootCont>
        <Root deleting={isDeleting} isError={isError}>
          <CustomLink
            className='card'
            to={href}
            onClick={evt => {
              isError && evt.preventDefault()
              if (evt.shiftKey) {
                evt.preventDefault()
                console.log(id)
                try {
                  setCopyId(true)
                  var copyText = document.getElementById('input-' + id)
                  copyText.select()
                  copyText.setSelectionRange(0, 99999) /* For mobile devices */
                  document.execCommand('copy')
                  toast.success('Collection ID Copied!')
                } catch (error) {
                  console.log(error)
                }
                setCopyId(false)
              }
            }}>
            {copyId && <div style={{opacity: 0, position: 'absolute'}}><input id={`input-${id}`} type='text' value={id} readOnly /></div>} 
            <Body>
              <div className='collection-id' style={{display: 'none'}}>{id}</div>
              { isOwner === false && <OwnerLock isChangingOwner={isChangingOwner} uid={collection.userId} /> }
              <TimeLabel deleting={isDeleting}>
                <LightTooltip title={startedString} placement='top' enterDelay={1000}>
                  <div>Uploaded {timeAgo.format((started || 0) * 1000)}</div>
                </LightTooltip>
              </TimeLabel>
              <Name name={name} id={collection.id} nameIsEditable={nameIsEditable} onBlur={handleNameBlur} isError={isError} />
              <Snippet cid={id}  snippet={snippet} message={message} />
            </Body>
          </CustomLink>
          <Footer>
            <Status isError={isError} shouldBeDoneButIsNot={shouldBeDoneButIsNot} collection={collection} />
            <CollectionMenu
              {...collection}
              shouldBeDoneButIsNot={shouldBeDoneButIsNot}
              onEditName={handleEditName}
              onRetryComplete={handleOnRetryComplete}
              onChangeOwner={handleMakeOwner}
              onDelete={handleDelete}
              isProcessing={isProcessing}
              isError={isError} >
              <FontAwesomeIcon style={{color: '#CCC'}} icon={faEllipsisH} />
            </CollectionMenu>
          </Footer>
        </Root>
      </RootCont>
    </EditorContexts>
  )
}
