import React from 'react'
import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'

const Progress = styled.div`
  position: relative
`

const TopProgress = styled(CircularProgress)`
  color: ${props => `${props.bgcolor ? props.bgcolor : '#7DBB91'} !important`};
  z-index: 1;
  position: absolute;
`

const BottomProgress = styled(CircularProgress)`
  color: ${props => `${props.bgcolor ? props.bgcolor : '#d4d4d4'} !important`};
  position: 'absolute';
  left: 0;
`

export default (props) => {
  const {topColor, bottomColor, className, value, size} = props
  return (
    <Progress className={className}>
      <TopProgress size={size || 25} variant='determinate' value={value} bgcolor={topColor} />
      <BottomProgress size={size || 25} variant='determinate' value={100} bgcolor={bottomColor} />
    </Progress>
  )
}
