import client from 'api/client'

export const updateDictionary = ({id, uid, dictionary}) =>
  client().patch(`/user/v2/${uid}/dictionary/${id}`, dictionary)

export const deleteDictionary = ({uid, id}) =>
  client().delete(`/user/v2/${uid}/dictionary/${id}`)

export const getDictionary = ({id, uid}) =>
  client().get(`/user/v2/${uid}/dictionary/${id}`)

export const getDictionaries = ({uid}) =>
  client().get(`/user/v2/${uid}/dictionary/`)
  