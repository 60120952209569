import {
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import {fetchUsage as fetchUsageApi} from 'api/usage'

// Actions
export const fetchUsage = createAsyncThunk(
  'user/usage',
  async (params, {getState, dispatch}) => {
    const {oid, uid} = params || {}
    if (!oid || !uid) return console.log('No uid or oid to fetch plan.')
    // console.log('fetchUsage', params)
    try {
      const {data} = await fetchUsageApi({oid, uid})
      return data
    } catch (error) {
      console.log(error)
    }
  })

// Slice
export const usageAdapter = createEntityAdapter()
const initialState = usageAdapter.getInitialState({})
export const slice = createSlice({
  name: 'usage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsage.fulfilled, (state, {meta, payload}) => {
        const oid = meta?.arg?.oid
        usageAdapter.upsertOne(state, {id: oid, ...payload})
      })
  }
})

const reducer = slice.reducer
export default reducer

// Selectors
const sliceSelector = state => state.usage

export const selectUsage = createSelector(
  [sliceSelector],
  (usage) => usage.entities
)

export const selectOrgUsage = createSelector(
  [selectUsage, (_, oid) => oid],
  (usage, oid) => usage[oid]
)
