import React, {useState, useEffect, Fragment} from 'react'
import {Card, Heading, ContentWrapper, SubHeading} from '../Settings/StyledComponent'
import styled from 'styled-components'
import Members from './Members'
import useCollection from 'hooks/useCollection'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle as faClose} from '@fortawesome/free-solid-svg-icons'

const Close = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  top: 42px;
  left: 42px;
  z-index: 2000;
  color: #CCC;
  &:hover {
    color: #777;
  }
  transition: color 1s;
`

const PageWrapper = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #ECF0F4;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 10vh;
  opacity:0;
  ${({show}) => show && 'opacity: 1'};
  transition: opacity .5s;
`

const ShareCard = ({id}) => {
  const {collection} = useCollection({id})
  const {orgId, name} = collection || {}
  return (
    <Fragment>
      <Heading>Share "{name}"</Heading>
      <SubHeading>Share the collection with your workspace members.</SubHeading>
      <Card>
        <Members orgId={orgId} cid={id} />
      </Card>
    </Fragment>
  )
}

export default () => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState()
  const {isShareModalOpen, showShareModal} = useCollection({id})

  const onClose = () => {
    setShow(false)
    setTimeout(() => {
      showShareModal(false)
    }, 500)
  }

  useEffect(() => {
    if (isShareModalOpen) {
      setShow(true)
      setId(isShareModalOpen)
    }
  }, [isShareModalOpen])

  return (
    isShareModalOpen && (
      <PageWrapper show={show}>
        <Close icon={faClose} size={'2x'} onClick={onClose}>Close</Close>
        <ContentWrapper>
          {id && <ShareCard id={id} /> }
        </ContentWrapper>
      </PageWrapper>
    )
  )
}
