import React from 'react'
import cx from 'classnames'

const Word = React.memo(({isFirst, wordObject}) => {
  const {
    bold,
    bookmark,
    end_time: endTime,
    start_time: startTime,
    strike,
    type,
    skip,
    alternatives: [{content, confidence}]
  } = wordObject

  const isPronunciation = type === 'pronunciation'

  const classes = {
    bold: !!bold,
    strike: !!strike,
    bookmark: !!bookmark,
    'low-confidence': parseFloat(confidence) < .84 ,
    pronunciation: isPronunciation
  }

  return (
    <>
      {isPronunciation && !isFirst && (
        <span
          className={cx('space', {bookmark: !!bookmark})}
        >
          {' '}
        </span>
      )}

      <span
        className={cx('word', classes)}
        data-bookmark={bookmark}
        data-start_time={startTime}
        data-end_time={endTime}
        data-skip={skip}
        data-type={type}
        data-confidence={confidence}
        data-bold={!!bold || undefined}
        data-strike={!!strike || undefined}
      >
        {content}
      </span>
    </>
  )
})

export default Word
