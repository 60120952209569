import React from 'react'
import SVGInline from 'react-svg-inline'

const humanSvg = `
<svg width="30px" height="30px" viewBox="0 0 44 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <circle id="path-1" cx="13.5" cy="18.5" r="13.5"></circle>
        <filter x="-24.1%" y="-20.4%" width="148.1%" height="148.1%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.133605072 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <circle id="path-3" cx="8" cy="8" r="8"></circle>
        <filter x="-40.6%" y="-34.4%" width="181.2%" height="181.2%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.133605072 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Customer" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Transcribing" transform="translate(-671.000000, -428.000000)">
            <g id="Group-16" transform="translate(675.000000, 431.000000)">
                <g id="Oval-2">
                    <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                    <use fill="#7DBB91" fill-rule="evenodd" xlink:href="#path-1"></use>
                </g>
                <path d="M21.3777065,20.1515152 C21.3777065,19.9795584 21.5170116,19.8401598 21.6888532,19.8401598 C21.8606949,19.8401598 22,19.9795584 22,20.1515152 C22,22.3685702 20.89109,24.1843614 18.9887874,25.4583203 C17.3843483,26.5328021 15.2935545,27.1428571 13.4592661,27.1428571 C11.5756927,27.1428571 9.53143933,26.5941898 7.97318034,25.5739797 C6.08508539,24.3378222 5,22.5036102 5,20.1515152 C5,19.9795584 5.13930515,19.8401598 5.31114676,19.8401598 C5.48298837,19.8401598 5.62229352,19.9795584 5.62229352,20.1515152 C5.62229352,22.2837955 6.59449167,23.9271847 8.31388575,25.0528918 C9.76543987,26.0032409 11.6913537,26.5201465 13.4592661,26.5201465 C15.1726476,26.5201465 17.1427372,25.9453107 18.6426786,24.9408103 C20.3822707,23.7758175 21.3777065,22.1458354 21.3777065,20.1515152 Z M18.3227385,17.037962 C17.7603478,17.037962 17.30444,16.5817487 17.30444,16.018981 C17.30444,15.4562133 17.7603478,15 18.3227385,15 C18.8851292,15 19.341037,15.4562133 19.341037,16.018981 C19.341037,16.5817487 18.8851292,17.037962 18.3227385,17.037962 Z M8.87519145,17.037962 C8.31280073,17.037962 7.85689297,16.5817487 7.85689297,16.018981 C7.85689297,15.4562133 8.31280073,15 8.87519145,15 C9.43758218,15 9.89348994,15.4562133 9.89348994,16.018981 C9.89348994,16.5817487 9.43758218,17.037962 8.87519145,17.037962 Z" id="Combined-Shape" stroke="#FFFFFF" fill="#FFFFFF" fill-rule="nonzero"></path>
                <g id="Group-8" transform="translate(20.000000, 0.000000)">
                    <g id="Oval">
                        <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-3"></use>
                    </g>
                    <g id="Group-6" transform="translate(6.000000, 6.000000)" stroke="#7DBB91" stroke-linecap="square" stroke-width="2">
                        <path d="M0.0857142857,2.62105263 L1.80855011,4.34388845" id="Line-4"></path>
                        <path d="M6,0.135338346 L1.80855011,4.34388845" id="Line-4"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`

export default (props) => <SVGInline svg={humanSvg} {...props} />
