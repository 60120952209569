import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  z-index: ${props => props.index ? props.index : 2000};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  position: fixed;
  
  ${props => props.dark && `background-color: rgba(236,240,244, ${props.opaque ? 1 : 0.8});`}  
  ${props => props.darkish && `background-color: rgba(236,240,244, ${props.opaque ? 1 : 0.3});`}
  ${props => props.background && `background-color: ${props.background};`}
  display: flex;
  align-items: ${props => props.top ? 'flex-start' : 'center'};
  justify-content: center;
`

export const Modal = styled.div`
  background-color: rgba(255, 255, 255, 0.85);

  padding: 40px 20px;
  margin-top: -15%; 

  text-align: center;
  margin: auto;
  
  border-radius: 3px;
  color: rgba(0,0,0,.9);
  box-shadow: 0 0 7px rgba(0,0,0,0.05);

  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 600px;
  max-width: 100%;
  .actions-cont {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }
`

const Overlay = ({children, dark, darkish, onClick, onMouseMove, className, opaque, top, index}) => (
  <Root
    dark={dark}
    opaque={opaque}
    top={top}
    darkish={darkish}
    index={index}
    className={className}
    onMouseMove={evt => onMouseMove && onMouseMove(evt)}
    onClick={evt => onClick && onClick(evt)}>
    <div>
      {children}
    </div>
  </Root>
)

export default Overlay
