import {
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import {
  complete as completeApi,
  rereview as rereviewApi,
  pause as pauseApi,
  reject as rejectApi,
  transcript as getTranscriptApi,
  fetch as fetchApi,
  count as countApi,
  list as listApi,
  stats as listStatsApi,
  progress as listProgressApi,
  release as releaseApi,
  take as takeApi,
  fetchMyStats as fetchMyStatsApi,
  fetchLeaderboard as fetchLeaderboardApi
} from 'api/correction'
import {toast} from 'components/common/Toaster'

// Actions
export const complete = createAsyncThunk(
  'correction/complete',
  async ({locale = 'us', language = 'en', id, comment, rating}, {getState}) => {
    const {data} = await completeApi({locale, id, language, comment, rating})
    return data
  })

export const pause = createAsyncThunk(
  'correction/pause',
  async ({locale = 'us', id}, {getState}) => {
    const {data} = await pauseApi({locale, id})
    return data
  })

export const reject = createAsyncThunk(
  'correction/reject',
  async ({locale = 'us', id, comment}, {getState}) => {
    const {data} = await rejectApi({locale, id, comment})
    const state = getState()
    const corrections = {...state.corrections}
    const ids = [...state.corrections.ids].filter(cid => cid !== id)
    const entities = {...corrections.entities}
    delete entities[id]
    corrections.entities = entities
    corrections.ids = ids
    state.corrections = corrections
    return data
  })

export const rereview = createAsyncThunk(
  'correction/rereview',
  async ({locale = 'us', notes, language = 'en', id}, {getState}) => {
    const {data} = await rereviewApi({locale, id, notes, language})
    return data
  })

export const release = createAsyncThunk(
  'correction/release',
  async ({locale, id}, {getState}) => {
    const {data} = await releaseApi({locale: 'us', id})
    return data
  })

export const take = createAsyncThunk(
  'correction/release',
  async ({locale, id}, {getState}) => {
    const {data} = await takeApi({locale, id})
    return data
  })

export const list = createAsyncThunk(
  'correction/list',
  async ({locale, uid}, {getState}) => {
    const {data} = await listApi({locale, uid})
    return data
  })

export const listStats = createAsyncThunk(
  'correction/stats/all',
  async ({languageCode: language}, {getState}) => {
    const [{data: us}, {data: eu}] = await Promise.all([
      listStatsApi({locale: 'us', language}),
      listStatsApi({locale: 'eu', language})
    ])
    
    const state = getState()
    const corrections = {...state.corrections}
    corrections.stats = us.concat(eu).reduce((acc, obj) => {
      acc[obj.id] = obj
      return acc
    }, {})
    state.corrections = corrections
    return corrections.stats
  })

export const fetchMyStats = createAsyncThunk(
  'correction/stats/user',
  async ({languageCode: language}, {getState}) => {
    const {data} = await fetchMyStatsApi({locale: 'us', language})
    const state = getState()
    const corrections = {...state.corrections}
    corrections.myStats = {}
    corrections.myStats = data.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    },{})
    state.corrections = corrections
    return corrections.myStats
  })

export const fetchLeaderboard = createAsyncThunk(
  'correction/leaderboard',
  async ({languageCode: language}, {getState}) => {
    const currentdate = new Date()
    const last3Months = new Date(currentdate.getFullYear(), currentdate.getMonth() - 3, 0).toISOString().split('T')[0]
    const {data} = await fetchLeaderboardApi({locale: 'us', language, date: last3Months})
    const state = getState()
    const corrections = {...state.corrections}
    corrections.leaderboard = data
    state.corrections = corrections
    return corrections.leaderboard
  })

export const listProgress = createAsyncThunk(
  'correction/progress/',
  async ({languageCode: language, locale = 'us'}, {getState}) => {
    console.log({language, locale})
    const {data} = await listProgressApi({locale, language})
    return data
  })

export const getTranscript = createAsyncThunk(
  'correction/transcript',
  async ({locale = 'us', id}, {getState}) => {
    const {data} = await getTranscriptApi({locale, id})
    return data
  })

export const fetch = createAsyncThunk(
  'correction/fetch',
  async ({locale = 'us', languageCode: language = 'en', test = false}, {getState}) => {
    try {
      const {data} = await fetchApi({locale, language, test})
      return data  
    } catch ({response}) {
      const message = typeof response.data === 'string' ? response.data : 'Unable to fulfill request'
      toast.error(message, {autoClose: 8000})
      throw new Error(message)
    }
  })

export const countEu = createAsyncThunk(
  'correction/count',
  async ({languageCode: language = 'en'}, {getState}) => {
    const eu = await countApi({locale: 'eu', language})
    const state = getState()
    const corrections = {...state.corrections}
    corrections.count[language].eu = eu.data
    corrections.count[language].total += eu.data
    state.corrections = corrections
    return state.corrections.en
  })

export const count = createAsyncThunk(
  'correction/count',
  async ({languageCode: language = 'en'}, {getState, dispatch}) => {
    const us = await countApi({locale: 'us', language})
    const state = getState()
    const corrections = {...state.corrections}
    corrections.count = {[language]: {}}
    corrections.count[language] = {
      us: us.data,
      eu: 0,
      loaded: true, 
      total: us.data
    }
    state.corrections = corrections
    dispatch(countEu({languageCode: language}))
    return state.corrections.en
  })

export const correctionsAdapter = createEntityAdapter()
const initialState = correctionsAdapter.getInitialState({
  count: {},
  stats: {}
})

export const slice = createSlice({
  name: 'corrections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetch.fulfilled, correctionsAdapter.upsertOne)
    builder.addCase(getTranscript.fulfilled, correctionsAdapter.upsertOne)
    builder.addCase(complete.fulfilled, correctionsAdapter.removeOne)
    builder.addCase(reject.fulfilled, correctionsAdapter.removeOne)
    builder.addCase(pause.fulfilled, correctionsAdapter.upsertOne)
    builder.addCase(list.fulfilled, correctionsAdapter.upsertMany)
    builder.addCase(listProgress.fulfilled, correctionsAdapter.upsertMany)
  }
})

const reducer = slice.reducer
export default reducer

// Selectors
const sliceSelector = state => state.corrections
const {
  selectById,
  selectEntities,
  selectAll
} = correctionsAdapter.getSelectors(sliceSelector)

export const selectCorrectionById = selectById
export const selectCorrections = selectEntities
export const selectOrderedCorrections = selectAll

export const selectCorrectorCorrection = createSelector(
  [selectOrderedCorrections, (_, uid, languageCode) => ({uid, languageCode})],
  (corrections, {uid, languageCode}) => corrections.find(correction => {
    const correctionLanguage = (correction?.languageCode || '').split('-')[0]
    if (languageCode !== correctionLanguage) return false
    // console.log({languageCode, correctionLanguage})
    let isPaused = false
    if (correction?.effort?.paused) {
      const nowSec = new Date().getTime() / 1000
      isPaused =  nowSec - correction?.effort?.paused < 60 * 60 // paused for less than an hour (sec * minutes)
    }
    return correction.corrector === uid && !isPaused && !correction?.effort?.rejected
  }) 
)

export const selectCorrection = createSelector(
  [selectCorrections, (_, uid, cid, languageCode) => ({uid, cid, languageCode})],
  (corrections, {uid, cid, languageCode}) => {
    const correction = corrections[cid]
    return correction
  }
)

export const selectCorrectorCorrections = createSelector(
  [selectOrderedCorrections, (_, uid, languageCode) => ({uid, languageCode})],
  (corrections, {uid, languageCode}) => corrections.filter(correction => {
    const correctionLanguage = (correction?.languageCode || '').split('-')[0]
    if (languageCode !== correctionLanguage) return false
    // console.log({languageCode, correctionLanguage})
    const paused = correction?.effort?.paused
    let isPaused = false
    if (paused) {
      const nowSec = new Date().getTime() / 1000
      const since = nowSec - paused
      isPaused =  since < 60 * 60 // paused for less than an hour (sec * minutes)
    }
    return correction.corrector === uid && !isPaused && !correction?.effort?.rejected
  }).sort((a,b) => b.tModified - a.tModified)
)

export const selectPausedCorrections = createSelector(
  [selectOrderedCorrections, (_, uid) => uid],
  (corrections, uid) => {
    // console.log('asdf', corrections, uid)
    return corrections
  }
)

export const selectAvailableCount = createSelector(
  [sliceSelector, (_, languageCode) => languageCode],
  ({count}, languageCode) => count[languageCode]
)

export const selectStats = createSelector(
  [sliceSelector],
  ({stats}) => Object.entries(stats).map(([id, data]) => data) || []
)

export const selectUID = (state, uid) => uid
export const selectStartTime = (state, _, startTime) => startTime
export const selectEndTime = (state, _, __, endTime) => endTime || new Date().getTime()
export const selectSort = (state, _, __, ___, sort) => sort

export const leaderboardSelector = createSelector(
  [sliceSelector],
  ({leaderboard = {}}) => leaderboard
)

export const myStatsSelector = createSelector(
  [sliceSelector, selectUID],
  ({leaderboard = {}}, uid) => leaderboard[uid] || []
)

export const selectMyStats = createSelector(
  [myStatsSelector, selectStartTime, selectEndTime],
  (collections = [], since = 0, end) => (collections || []).reduce((acc, {cid, timeStamp, seconds, correctors = ''}) => {
    timeStamp > since && timeStamp < end && acc.push({
      id: cid,
      seconds: seconds, //collections.reduce((acc, {seconds, timeStamp}) => (timeStamp > since && timeStamp < end ? acc + seconds : acc), 0),
      // count: collections.reduce((acc, {seconds, timeStamp}) => (timeStamp > since && timeStamp < end ? acc + 1 : acc), 0),
      completedAt: timeStamp,
      shared: !!correctors.split(' ').length
    })
    return acc
  }, [])
)

export const leaderboardEntriesSelector = createSelector(
  [leaderboardSelector],
  (leaderboard) => Object.entries(leaderboard || {})
)

export const selectLeaderboard = createSelector(
  [leaderboardEntriesSelector, selectStartTime, selectEndTime, selectSort],
  (leaderboard, since = 0, end, sort = 'count') => {
    // console.log({leaderboard})
    return leaderboard.map(([uid, stats]) => {
      const seconds = stats.reduce((acc, {seconds, timeStamp}) => (timeStamp >= since && timeStamp <= end ? acc + seconds : acc), 0)
      const count = stats.reduce((acc, {seconds, timeStamp}) => (timeStamp >= since && timeStamp <= end ? acc + 1 : acc), 0)
      // console.log('leaderboard', uid, seconds, count, stats)
      return {
        uid,
        seconds,
        count
      }
    }).sort((a,b) => b[sort] - a[sort])
  }
)

