import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {columns} from './CollectionsTableView'
import React, {useEffect, useState} from 'react'
import useCollection from 'hooks/useCollection'
import styled from 'styled-components'
import {Name, Snippet, Status} from './CollectionCard'
import CollectionMenu from '../Popover/CollectionMenu'
import {useInterval} from 'react-interval-hook'
import useProfile from 'hooks/useProfile'
import EditorContexts from 'context/EditorContexts'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {useHistory} from 'react-router-dom'
import TranscriptionProgress from 'components/common/CreatorDialog/TranscriptionProgress'
import Hidden from '@material-ui/core/Hidden'

const StyledTableRow = styled(TableRow)`
  ${({loading}) => loading && 'animation: Pulsate 1s linear infinite;'}
`
const PaddedCell = styled(TableCell)`
padding: 16px 30px !important;
`
// const Text = styled.p`
//   margin: 0;
//   color: #37404A;
//   font-size: 16px;
//   line-height: 24px;
//   font-weight: 500;
//   letter-spacing: 0.3px;
//   outline: none;
//   overflow: hidden;
//   max-height: 96px;
// `

export const CollectionsTableRow = ({id}) => {
  const {deleteCollection, fetchCollection, makeOwner, message, collection, isProcessing, shouldBeDoneButIsNot, expectedTimeLeft} = useCollection({id})
  const {uid} = useProfile()
  const {snippet, paused, isError, tProgress, props = {}, transcriptionStarted, started, tCompletion = {}} = collection
  const [name, setName] = useState(collection.name ? collection.name : '-')
  const [nameIsEditable, setNameIsEditable] = useState(false)
  const [, setChangingOwner] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const href = `/edit-v2/${collection.id}/`
  const history = useHistory()

  const {duration} = props
  const waitFor = () => {
    if (!duration) return 5000
    else if (expectedTimeLeft > 0) return 1000
    else return 10000
  }
  const handleEditName = () => setNameIsEditable(true)

  const handleDelete = evt => {
    setIsDeleting(true)
    deleteCollection()
  }

  const handleMakeOwner = async () => {
    setChangingOwner(true)
    await makeOwner(uid)
    setChangingOwner(false)
  }
  const {start, stop, isActive} = useInterval(
    () => {
      fetchCollection()
    },
    waitFor(),
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: true,
      onFinish: () => {
        if (!snippet) fetchCollection()
      }
    }
  )
  const handleOnRetryComplete = () => {
    !isActive() && start()
  }
  const handleNameBlur = title => {
    setNameIsEditable(false)
    setName(title)
  }

  useEffect(() => {
    isProcessing && start()
    tProgress !== undefined && tProgress === 100 && isActive() && stop()
  }, [tProgress])

  const renderRow = (column) => {

    switch (column.id) {
    case 'started':
      return (
        <Hidden smDown key={column.id}>
          <TableCell onClick={() => history.push(href)} key={column.id} align={column.align}>
            {started && new Date(started * 1000).toLocaleDateString()}
          </TableCell>
        </Hidden>
      )
    case 'body':
      if (duration && transcriptionStarted && !tCompletion.draft && !message) {
        return (
          <Hidden smDown key={column.id}>
            <TableCell onClick={() => history.push(href)} key={column.id} align={column.align}>
              <TranscriptionProgress bar started={transcriptionStarted} duration={duration} disabled={paused} />
            </TableCell>
          </Hidden>
        )
      }
      return (
        <Hidden smDown key={column.id}>
          <TableCell onClick={() => history.push(href)} key={column.id} align={column.align}>
            <Snippet cid={id}  style={{width: '99%'}} snippet={snippet} message={message} />
          </TableCell>
        </Hidden>
      )
    case 'title':
      return (
        <TableCell onClick={() => history.push(href)} key={column.id} align={column.align}>
          <Name name={name} id={collection.id} nameIsEditable={nameIsEditable} onBlur={handleNameBlur} isError={isError} />
        </TableCell>
      )
    case 'status':
      return (
        <PaddedCell  key={column.id} align={column.align} >
          <Status isError={isError} shouldBeDoneButIsNot={shouldBeDoneButIsNot} collection={collection} paused={paused} dontShowProgress />
        </PaddedCell>
      )
    case 'actions':
      return (
        <TableCell width={35} key={column.id} align={column.align} style={{padding: 0}}>
          <CollectionMenu
            {...collection}
            shouldBeDoneButIsNot={shouldBeDoneButIsNot}
            onEditName={handleEditName}
            onRetryComplete={handleOnRetryComplete}
            onChangeOwner={handleMakeOwner}
            onDelete={handleDelete}
            isProcessing={isProcessing}
            isError={isError} >
            <MoreVertIcon style={{color: '#CCC'}} />
          </CollectionMenu>
        </TableCell>
      )
    default:
      return (
        <TableCell key={column.id} align={column.align}>
          {column.id}
        </TableCell>
      )
    }
  }

  return (
    <EditorContexts collectionId={collection.id} isCorrector={false}>
      <StyledTableRow loading={isDeleting ? 1 : 0} hover role="checkbox" tabIndex={-1} key={id} style={{cursor: 'pointer'}}>
        {columns.map(renderRow)}
      </StyledTableRow>
    </EditorContexts>
  )
}
