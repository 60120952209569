import React, {useEffect, useMemo} from 'react'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from '@material-ui/core/Paper'
import {CollectionsTableRow} from './CollectionsTableRow'
import {makeStyles} from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'

TimeAgo.addLocale(en)

const useStyles = makeStyles({
  table: {
    margin: 0,
    padding: 0,
    width: '100%',
    border: 0,
    backgroundColor: '#FFF',
    '& td': {
      borderColor: '#EEE'
    },
    '& .MuiTableRow-root.MuiTableRow-hover': {
      transition: 'background-color .5s',
      '&:hover': {
        backgroundColor: '#F8F8F8',
        transition: 'background-color 1s'
      }
    }
  },
  paper: {
    minHeight: '212px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '0 20px',

  },
  container: {
    borderRadius: 8
  }
})
const  paperNoCollection = {
  opacity: 0.3,
}

export const columns = [
  {id: 'actions', align: 'right'},
  {id: 'title', label: 'Name', minWidth: 170},
  {id: 'body', label: 'Snippet', minWidth: 200},
  {id: 'started', label: 'Started'},
  {id: 'status',  align: 'right', minWidth: 50},
]

const SERVER_MAX_VALUE = 20

const CollectionsTableView = (props) => {
  const {collections, usage, loading, loadPage, searchTerm, nextPage} = props
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const noCollection = !loading && collections.length === 0

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const shouldNextPageLoad = useMemo(() => {
    return nextPage && (collections.length - ((page + 1) * rowsPerPage)) < SERVER_MAX_VALUE
  }, [nextPage, rowsPerPage, page, collections.length])

  useEffect(() => {
    if ((searchTerm === '' || searchTerm === null)
     && !loading
     && shouldNextPageLoad) {
      loadPage({loadNextPage: true})
    }
  }, [page, rowsPerPage, searchTerm, loading, shouldNextPageLoad])

  return (
    <Paper className={classes.paper} style={{...(noCollection && paperNoCollection)}} elevation={0}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                // skip body and started if small screen
                if (column.id === 'body' || column.id === 'started')
                  return (
                    <Hidden key={column.id} smDown>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{minWidth: column.minWidth}}
                      >
                        {column.label}
                      </TableCell>
                    </Hidden>
                  )

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{minWidth: column.minWidth}}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                <TableRow>
                  <TableCell style={{width: 20}}/>
                  <TableCell style={{width: 100}}><Skeleton variant="text" /></TableCell>
                  <Hidden smDown>
                    <TableCell><Skeleton variant="text" /></TableCell>
                    <TableCell style={{width: 100}}><Skeleton variant="text" width={100} /></TableCell>
                  </Hidden>
                  <TableCell style={{width: 20}} />
                </TableRow>
              ) : 
                collections
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(collection => <CollectionsTableRow usage={usage} key={collection.id} id={collection.id} />) 
            }
            
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={collections.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

  )
}

export default CollectionsTableView
