import {useSelector, useDispatch} from 'react-redux'
import {
  fetchDictionaries as fetchDictionariesAction,
  fetchDictionaries as fetchDictionaryAction,
  updateDictionary as updateDictionaryAction,
  deleteDictionary as deleteDictionaryAction,
  selectOrderedDictionaries,
  selectDictionary,
  selectedDictionary
} from 'features/dictionaries'

export default ({id, uid, languageFilter}) => {
  const dispatch = useDispatch()

  const dictionaries = useSelector(state => selectOrderedDictionaries(state, languageFilter))

  const dictionary = useSelector(state => selectDictionary(state, id))

  const prevUpdatedDictionary = useSelector(state => selectedDictionary(state))

  const {language, terms = []} = dictionary || {}

  const fetchDictionaries = async () => {
    const {payload} = await dispatch(fetchDictionariesAction({uid}))
    return payload
  }

  const fetchDictionary = async ({id}) => {
    const {payload} = await dispatch(fetchDictionaryAction({uid, id}))
    return payload
  }

  const updateDictionary = async (dictionary) => {
    const {payload} = await dispatch(updateDictionaryAction({uid, id, dictionary}))
    return payload
  }

  const deleteDictionary = async ({id}) => {
    const {payload} = await dispatch(deleteDictionaryAction({uid, id}))
    return payload
  }

  return {
    deleteDictionary,
    fetchDictionary,
    fetchDictionaries,
    updateDictionary,
    dictionaries,
    dictionary,
    language,
    terms,
    prevUpdatedDictionary
  }
}
