import React from 'react'
import styled from 'styled-components'
import Logo from 'static/images/logo.png'

const Img = styled.img`
  width: 86px;
`

export default () => {
  return <Img src={Logo} alt='Logo' />
}
