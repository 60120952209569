import client from 'api/client'

export const addSpeaker = ({collectionId, startTime, name = '', locale}) => client().post(
  `/collections/v2/${collectionId}/speakers/${startTime}`,
  {name, locale}
)

export const updateSpeaker = ({collectionId, speakerId, name, locale}) => client().put(
  `/collections/v2/${collectionId}/speakers/${speakerId}`,
  {name, locale}
)

export const removeSpeaker = ({collectionId, speakerId, locale}) => client().delete(
  `/collections/v2/${collectionId}/speakers/${speakerId}`, {locale}
)

export const updateSpeakers = ({collectionId, speakers, locale}) => client().put(
  `/collections/v2/${collectionId}/speakers`,
  {speakers, locale}
)

export const fetchSpeakers = ({collectionId, locale}) =>
  client().get(`/collections/v2/${collectionId}/speakers`, {locale})
