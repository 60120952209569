import React, {useEffect} from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'

import {firebaseAuth} from 'utils/Firebase'
import Button from 'components/common/Button'
import DeadFish from 'static/images/dead-fish.png'

const Cont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const H3 = styled.h3`
  text-align: center;
  color: #333;
  margin-top: 0px;
`

const ImageCont = styled.div`
  & img {
    width: 97px;
  }
`

const Block = styled.div`
  max-width: 530px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
`

const Subtext = styled.p`
  font-size: 15px;
  line-height: 25px;
  color: #555;
  margin-bottom: 30px;
  max-width: 400px;
`

const AlternativeCont = styled.div`
  margin: 30px;
  font-size: 13px;
  color: #777;
  & a:hover {
    color: #5173E7;
  }
`

const ChunkLoadError = ({openFeedbackForm}) => {
  return (
    <>
      <ImageCont>
        <img alt='upgraded' style={{width: 330}} src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/6158ea5fdfb1671290d7706d_upgrade.svg' />
      </ImageCont>

      <H3 style={{fontSize: 30, marginBottom: 10}}>We've updated Konch!</H3>

      <Subtext>
          A new update was pushed while you were working. Please refresh the page to get the latest and greatest.
      </Subtext>

      <Button primary raised onClick={() => window.location.reload()}>Refresh</Button>

      <AlternativeCont>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='/'>Dashboard</a><span> | </span>
        <span 
          tabIndex={0}
          role="button" 
          styling="text"
          onKeyDown={(evt) => evt.keyCode === 13 && openFeedbackForm}
          style={{outline: 'none', cursor: 'pointer'}}
          onClick={openFeedbackForm}
        >
          Feedback
        </span>
      </AlternativeCont>
    </>
  )
}

const GeneralError = ({openFeedbackForm}) => {
  return (
    <>
      <ImageCont>
        <img alt='fish fail' src={DeadFish} />
      </ImageCont>

      <H3>Something Fishy Happened</H3>

      <Subtext>
          We seem to have caught an unexpected error. We aplogize for the experience, and we'd like to get it fixed for you ASAP!
          Please let us know what you were doing and we will send a bug report of the incedent to the dev team.
      </Subtext>

      <Button primary raised onClick={openFeedbackForm}>Open Feedback Form</Button>

      <AlternativeCont>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='/'>Dashboard</a><span> | </span>
        <span 
          tabIndex={0}
          role="button" 
          styling="text"
          onKeyDown={(evt) => evt.keyCode === 13 && window.location.reload()}
          style={{outline: 'none', cursor: 'pointer'}}
          onClick={() => window.location.reload()}
        >
          Refresh
        </span>
      </AlternativeCont>
    </>
  )
}

export const ErrorMessage = ({error, componentStack, resetError}) => {
  let user = {} 
  let message = ''
  const errorName = error?.name || ''
  const isChunkLoadError = errorName === 'ChunkLoadError'

  try {
    console.log({error})
    message = error.message.split('\n')[0]
  } catch (error) {
    console.log('Could not get error message.', error)
  }

  try {
    user = firebaseAuth().currentUser || {}
    console.log(user)
  } catch (error) {
    console.log('Could not get user.')
  }

  const openFeedbackForm = () => {
    const params = {
      name: user.displayName || 'Your name',
      email: user.email || '',
      subject: 'Got an error when using konch',
      text: `[Let us know what happened...]\n\n\n\n\n------------\nerror: ${message}`,
    }
    try {
      window.Beacon('close')
      window.Beacon('navigate', '/ask/message')
      window.Beacon('prefill', params)
      window.Beacon('open')
    } catch (err) {
      try {
        Sentry.showReportDialog(params)
      } catch (err) {
        window.open(`mailto:suport@konch.ai?subject=I%20got%20an%20error&body=[Tell%20us%20what%20happened.]%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-------%0D%0Aerror:${message}`)
      }
    }
  }
  
  useEffect(() => {
    !isChunkLoadError && openFeedbackForm()
  }, [errorName])

  return (
    <Cont>
      <Block>
        {
          isChunkLoadError ? 
            <ChunkLoadError openFeedbackForm={openFeedbackForm} /> :
            <GeneralError openFeedbackForm={openFeedbackForm} /> 
        }
      </Block>
    </Cont>
  )
}

export default ({children}) => (
  <Sentry.ErrorBoundary 
    fallback={(props) => <ErrorMessage {...props} />} 
  >
    {children}
  </Sentry.ErrorBoundary>
)
