import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {createFirestoreInstance} from 'redux-firestore'
import {isProd} from 'utils/env'
import store from 'store'
import App from 'components/App'
import {fb} from 'utils/Firebase'
import EnvBanner from 'components/common/EnvBanner'
import Toaster from 'components/common/Toaster'
import ErrorBoundary from 'utils/ErrorBoundaryV2'
import {Welcome} from 'components/common/Dashboardv2/WelcomeModal'

const rrfConfig = {
  userProfile: 'Users',
  useFirestoreForProfile: true
}

const rrfProps = {
  firebase: fb,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

const Root = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          {/* App must come first for editro to render */}
          <App />
          { !isProd() && <EnvBanner /> }
          <Toaster autoClose={2000} />
          <Welcome />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </ErrorBoundary>
)

export default Root
