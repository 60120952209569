import {
  createAsyncThunk,
  createEntityAdapter,
  // createSelector,
  // current,
  createSlice
} from '@reduxjs/toolkit'
import {db, fb, removeUserFromOrg as removeUserFromOrgCall} from 'utils/Firebase'

// Actions
export const fetchUser = createAsyncThunk(
  'users/fetch',
  async ({uid}, {getState}) => {
    const state = getState()
    const cachedUser = state.users.entities[uid]
    if (cachedUser) return cachedUser
    if (state.users.loading[uid]) return
    state.users.loading[uid] = true
    const user = (await db.collection('Users').doc(uid).get()).data()
    state.users.entities[uid] = user
    delete state.users.loading[uid]
    return user
  })

// gets the user from an org by email
export const fetchUserByEmail = createAsyncThunk(
  'users/organization/fetch/email',
  async ({email, oid}, {getState}) => {
    const state = getState()
    const [uid, data = {}] = Object.entries(state.users.entities).filter(([uid, user]) => user.email === email)[0] || []
    console.log('state entries', uid, data, email, oid)
    if (uid) {
      const user = {uid, ...data}
      console.log('cached user:', user)
      return user
    }
    console.log('cachedUser not found')
    const {docs} = await db.collection('__search_').doc('Organizations').collection('indexes').doc(oid).collection('Users').where(`data.email`, '==', email).get()
    console.log({docs})
    let user = null
    if (docs.length > 0) {
      const uid = docs[0].id
      const {data} = docs[0].data()
      user = {uid, ...data}
      console.log('user from index', data)
    }
    return user
  })

export const fetchUsers = createAsyncThunk(
  'users/organization/fetchAll',
  async ({oid, q, uids, limit = 99}, {dispatch, getState}) => {
    const state = getState()
    let collection = db.collection('__search_').doc('Organizations').collection('indexes').doc(oid).collection('Users')

    if (q) {
      const query = `indexes.${q.replace('.', '__dot__').toLowerCase()}`
      console.log(query)
      collection = collection.where(query, '==', true)
    } else if (uids && uids.length > 0) {
      collection = collection.where(fb.firestore.FieldPath.documentId(), 'in', uids)
    } else {
      collection = collection.orderBy('data.lastSignInTime', 'desc')
      if (state.users.cursor) collection = collection.startAfter(state.users.cursor)
    }

    const users = await collection.limit(limit).get()
    return {users, q, uids} 
  })

export const removeUserFromOrg = createAsyncThunk(
  'users/organization/removeUser',
  async ({uid, oid}, {getState}) => {
    const state = getState()
    await removeUserFromOrgCall({uid, oid})
    const users = {...state.users.entities}
    console.log('users', users)
    delete users[uid].organizations[oid]
    state.firestore.entities = users
    return []
  })

// Slice
export const usersAdapter = createEntityAdapter()
const initialState = usersAdapter.getInitialState({
  ordered: []
})
export const slice = createSlice({
  name: 'users',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.fulfilled, (state, {meta, payload}) => {
        const {users, q, uids} = payload
        users.docs.map(doc => {
          state.entities[doc.id] = doc.data().data
          return state.entities[doc.id]
        })

        if (!q && !uids) {
          const last = users.docs[users.docs.length - 1]
          state.cursor = last
        }
      })
  }
})

const reducer = slice.reducer
export default reducer

// Selectors
export const sliceSelector = state => state.users
