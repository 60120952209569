import {createContext} from 'react'

const EditorContext = createContext({
  collectionId: null,
  locale: null,
  isCorrector: false,
  isReviewer: false
})

export default EditorContext
