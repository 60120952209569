import React, {useEffect} from 'react'
// import qs from 'query-string'
import {useMediaPredicate} from 'react-media-hook'
import {init as hjInit} from 'utils/Hotjar'
import * as Sentry from '@sentry/react'

import ProfileHeader from 'components/common/Dashboardv2/Header'
import BrowserSupportModal from 'components/common/BrowserSupportModal'
import Collections from 'components/common/Dashboardv2/Collections'
import Sidebar from 'components/common/Dashboardv2/Sidebar'
import CreateButton from 'components/common/Dashboardv2/CreateButton'
import CreateWorkspace from 'components/common/Dashboardv2/CreateWorkspace'
import UpdateEmail from 'components/common/Dashboardv2/UpdateEmail'
import Share from 'components/common/Share'
import Grid from '@material-ui/core/Grid'
// import {toast} from 'components/common/Toaster'
import Container from 'components/common/Dashboardv2/Container'
import useSearch from 'hooks/useSearch'
import useUpload from 'hooks/useUpload'
import useProfile from 'hooks/useProfile'
import SearchContext from 'context/SearchContext'
import styled from 'styled-components'
import DropZone from 'components/common/Dashboardv2/DropZone'
import UploadQueue from 'components/common/Dashboardv2/UploadQueue'
import Welcome from 'components/common/Dashboardv2/WelcomeModal'
import OverLimit from 'components/common/CreatorDialog/OverLimit'
import {isProd} from 'utils/env'

// let firstTimeTimeout
export const StyledGrid = styled(Grid)`
  &.showSidebar {
    padding: 60px 15px 300px;
  } 
`

export const StyledContainer = styled(Container)`
  max-width: 1590px;
`

export default (props) => {
  document.title = 'Dashboard - Konch'
  const {history: routerHistory, onSetLanguageDefault} = props
  // const [wait, setWait] = useState(true)
  const {updateSettings, profile, isLoaded, organization, oid, uid} = useProfile()
  const {openDialog} = useUpload()
  const showSidebar = useMediaPredicate('(min-width: 780px)')
  const history = routerHistory.history || routerHistory
  const {searchTerm, setSearchTerm, onChange, loading, setLoading} = useSearch({})

  const help = false // feature flag to start showing help screens

  useEffect(() => {
    const origBgColor = document.body.bgColor
    document.body.bgColor = '#ECF0F4'
    // const {payment} = qs.parse(props.location.search)
    updateSettings({homePage: 'dashboard'})
    // if (payment) {
    //   toast.success(<div>You're ready to go!<br />Drag in a file to this dashboard<br />or click upload "+" button to start.</div>, {autoClose: 5000})
    //   history.push('/dashboard')
    // }
    // clearTimeout(firstTimeTimeout)
    // setTimeout(() => setWait(false), 2000)
    return () => {
      document.body.bgColor = origBgColor
    }
  }, [])

  useEffect(() => {
    if (isLoaded) {
      try {
        const {email = '', name, locale: profLocale = 'us', isCorrector, creationTime} = profile || {}
        const {locale: orgLocale = 'us', billing} = organization || {}
        const {customer, plan, enterprise} = billing || {}
        const locale = `${profLocale} | ${orgLocale}`
        
        // HOTJAR: DON'T START HJ FOR NON DK AND EMPLOYEES
        if (!(email || '').endsWith('.dk') && !(email || '').endsWith('konch.ai') && !isCorrector && isProd()) {
          let isNewUser = true
          try {
            isNewUser = (new Date() - new Date(creationTime)) / 1000 < (60 * 60 * 48) // 48 hours in seconds
          } catch (error) {
            console.log('error getting new usr date check')
          }
          isNewUser && hjInit({uid, email})  
        }
        
        Sentry.setUser({email, uid, oid, locale})
        const params = {
          uid, 
          oid, 
          locale, 
          customer, 
          plan: enterprise ? 'enterprise' : plan, 
        }
        if (email) params.email = email
        
        window.Beacon('identify', params)
        
        window.Beacon('prefill', {
          email, 
          name, 
          subject: 'Help with Konch', 
          text: `\n\n[Pictures are worth a thousand words. When possible, try a screen shot :-). No matter what you choose, we are happy to to hear from you!]`})
        
        console.log(Beacon('info'))
      } catch (error) {
        console.log('dashboard isloaded error', error)
      }
    }
  }, [isLoaded])

  return (
    <SearchContext.Provider value={{searchTerm, setSearchTerm, loading, setLoading}}>
      <StyledContainer onDragOver={openDialog}>
        <ProfileHeader history={history} onSetLanguageDefault={onSetLanguageDefault} />

        <StyledGrid className='showSidebar' container direction='row' justifyContent='center' alignItems='flex-start'>

          {
            showSidebar && (
              <Grid item md={2} sm={2} xs={1}>
                <Sidebar onSearch={onChange} router={props.history} />
              </Grid>
            )
          }

          <Grid item md={10} sm={10} xs={11} >
            <Collections oid={oid} />
          </Grid>
        </StyledGrid>

        {
          profile?.isLoaded && (
            <>
              { help && <Welcome updateSettings={updateSettings} profile={profile} /> }
              <Share />
              <OverLimit />
              <UploadQueue />
            </>
          )
        }

        <CreateButton />
        <CreateWorkspace />
        <UpdateEmail />
        <BrowserSupportModal />
        <DropZone />
      
      </StyledContainer>
    </SearchContext.Provider>
  )
}
