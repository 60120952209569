import {createContext} from 'react'

const VideoPlayerStateContext = createContext({
  setVideoPlayerState: () => {},
  fullScreen: false,
  large: false,
  popout: false,
  playing: false,
  speed: 1
})

export default VideoPlayerStateContext
