import React, {Component} from 'react'
import styled from 'styled-components'
import Overlay from 'components/common/Overlay'
import FadeIn from 'react-fade-in'
import isEqual from 'lodash/isEqual'

const Wrapper = styled.span`
  cursor: pointer;
  position: relative;
`
const Trigger = styled.span`
  cursor: pointer;
  user-select: none;
  ${props => props.disabled && `
    opacity: 0.5;
    cursor: wait;
  `}
  z-index: 1300;
`

const ContentWrap = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 2001;
`

const Content = styled.div`
  cursor: pointer;
  opacity: 1;
  position: absolute;
  margin-top: 8px;
  display: inline-block;
  z-index: 1300;
  background-color: #FFF;
  color: #37404A;
  padding: ${props => props.padding || '8px'};
  border-radius: 6px;
  border: 1px solid rgba(151, 151, 151, 0.12);
  box-shadow: 0 2px 29px rgba(0,0,0,0.1);
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.5px;
  ${props => props.width && `width: ${props.width}px;`}
  ${props => props.message && `
    padding: 20px 20px;
    line-height: 25px;
    white-space: normal;
    width: 350px;
  `}
  transform: translateY(-5px);
  ${props => props.contentLeft && 'transform: translateX(-50%) translateY(-5px);'}
  ${props => props.contentFarLeft && 'transform: translateX(-103%) translateY(-50px);'}
  ${props => props.leftTransform && `transform: translateX(${props.leftTransform}) translateY(${props.topTransform || '0px'});`}
  ${props => props.hide && 'opacity:0;'}
  transition: opacity 0.5s;
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 3px;
  ${props => props.noHover
    ? 'opacity: 0.5;'
    : `
    :hover {
      background: #F4F4F4;
    }
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  & span {
    margin-left: 15px;
  }
  ${props => props.disabled && `
      opacity: 0.2;
      cursor: default;
      user-select: none;
  `}
`

export const MenuIcon = styled.div`
  margin-left: -6px;
  width: 18px;
  text-align: center;
`

class Popover extends Component {
  state = {
    open: false,
    hide: false,
    disabled: false
  }

  clearTimeouts = () => {
    clearTimeout(this.closing)
    clearTimeout(this.opener)
    clearTimeout(this.readyToAutoClose)
  }

  init = (props) => {
    this.setState({
      open: props.message || props.open === true,
      disabled: props.disabled
    })
  }

  componentDidMount = () => {
    this.readyToClose = false
    this.clearTimeouts()
    this.init(this.props)
  }

  getPropsRequiredByInit = ({message, disabled}) => ({message, disabled});

  componentDidUpdate (prevProps) {
    if (!isEqual(this.getPropsRequiredByInit(prevProps), this.getPropsRequiredByInit(this.props))) {
      this.init(this.props)
    }
  }

  componentWillUnmount = () => {
    this.clearTimeouts()
  }

  toggleContent = () => {
    if (this.state.open) this.closeContent()
    else this.openContent()
  }

  startAutoClose = () => {
    this.readyToAutoClose = setTimeout(() => {
      this.readyToClose = true
    }, 2000)
  }

  openContent = () => {
    this.clearTimeouts()
    this.readyToClose = false
    this.setState({
      open: true,
      hide: false
    })
    this.startAutoClose()
  }

  closeContent = (event) => {
    this.clearTimeouts()
    this.setState({
      open: false
    })
    this.readyToClose = false
    this.props.onClose && this.props.onClose()
  }

  handleMouseOut = () => {
    if (this.readyToClose && !this.props.message) {
      this.setState({hide: true})
      clearTimeout(this.closing)
      this.closing = setTimeout(() => this.closeContent(), 700)
    }
  }

  render () {
    const open = this.state.open || this.props.show
    const {trigger, content, hover, message, ...rest} = this.props
    const {hide, disabled} = this.state

    return (
      <Wrapper {...rest} >
        <Trigger
          onClick={() => !disabled && this.toggleContent()}
          disabled={disabled}
          onMouseOver={() => {
            if (hover && open) {
              clearTimeout(this.opener)
              this.opener = setTimeout(() => {
                this.openContent()
              }, 1000)
            }
          }}
          onMouseOut={() => {
            hover && clearTimeout(this.opener)
          }}>
          {trigger}
        </Trigger>

        <ContentWrap >
          {open ? (
            <FadeIn>
              <Content {...this.props}
                hide={hide}
                onMouseMove={() => {
                  this.clearTimeouts()
                  this.startAutoClose()
                }}>
                {content}
              </Content>
            </FadeIn>
          ) : null}
        </ContentWrap>
        { open && <Overlay dark={message} onClick={this.closeContent} onMouseMove={() => this.handleMouseOut(true)} /> }
      </Wrapper>
    )
  }
}

export default Popover
