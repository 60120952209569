import client from 'api/client'

export const fetch = (id, versionId, locale) =>
  client().get(`/collections/v2/${id}${versionId ? `/versions/${versionId}` : ''}`, {locale})

export const fetchAll = ({uid, oid: orgId, nextPage, orgWide, shared}) =>
  client().post(`/user/${shared ? '' : 'v2/'}${uid}/collections${shared ? '/shared' : ''}`,
    {orgId, nextPage, orgWide}
  )

export const search = ({oid, term, nextPage, orgWide}) =>
  client().post(`/collections/v2/search`, {orgId: oid, term, nextPage, orgWide})

export const fetchSubtitles = (id, format = 'srt') =>
  client().get(`/collections/v2/${id}/subtitles`, {params: {format}})

export const rename = (id, name) =>
  client().patch(`collections/v2/${id}`, {name})

export const remove = (id, name) =>
  client().delete(`collections/v2/${id}`)

export const share = ({id, uid: userId, remove}) => {
  console.log('share api', {id, userId, remove})
  const call = remove ? client().delete : client().post
  return call(`collections/v2/${id}/share`, {userId, data: {userId}})
}

export const owner = ({id, uid: userId}) =>
  client().post(`collections/v2/${id}/owner`, {userId})

export const retry = ({id}) =>
  client().post(`/pipeline/collection/${id}/retry`)

export const unPause = ({id}) =>
  client().post(`/pipeline/collection/${id}/unpause`)

export const checkin = ({id}) =>
  client().post(`/collections/v2/${id}/checkin/`)

export const recoverVersion = (id, versionId) =>
  client().patch(`/collections/v2/${id}/versions/${versionId}`)

export const upgradeToPrecision = (id) =>
  client().post(`/collections/v2/${id}/precision`)
