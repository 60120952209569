export const pad = (n, width, z) => {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const dictToTimeStamp = (timeDict, inclMs) => `${timeDict.hh}:${timeDict.mm}:${timeDict.ss}${inclMs ? `.${timeDict.ms}` : ''}`

export const getTimeStamp = (secs, inclMs) => dictToTimeStamp(getTimeDict(secs), inclMs)

export const getHumanTime = (secs) => {
  
  const timeDict = getTimeDict(secs)
  // console.log(timeDict)
  if (timeDict.hours > 0) return `${timeDict.hours} hour${timeDict.hours === 1 ? '' : 's'}`
  if (timeDict.minutes > 0) return `${timeDict.minutes} minute${timeDict.minutes === 1 ? '' : 's'}`
  if (timeDict.seconds > 0) return `${timeDict.seconds} second${timeDict.seconds === 1 ? '' : 's'}`
}

export const getTimeDict = (secs) => {
  const time = {}
  secs = secs || 0
  const dec = secs % 1 // get just decimal valie
  const minutes = Math.floor(secs / 60)
  time.seconds = Math.trunc(secs) % 60
  time.hours = Math.floor(minutes / 60)
  time.minutes = minutes % 60
  time.milSecs = parseFloat(dec.toFixed(3), 10) * 1000

  time.hh = pad(time.hours, 2)
  time.mm = pad(time.minutes, 2)
  time.ss = pad(time.seconds, 2)
  time.ms = pad(time.milSecs, 3)
  return time
}

export default getTimeDict
