import styled, {css} from 'styled-components'

const primary = css`
  color: ${props => props.theme.primary};
`

const accent = css`
  color: ${props => props.theme.accent};
`

const light = css`
  &:hover {
    color: #FFF;
  }
`

const mini = css`
  color: #c4c5c5;
  height: 27px;
  line-height: initial;
  white-space: nowrap;
  padding: 0 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 9px;
`

const secondary = css`
  font-weight: 400;
  color: #BBB;
  cursor: pointer;
  margin-right: 8px;
  margin-left: 8px;
  &:hover {
    color: #333;
    background-color: transparent;
    transition: all .3s;
  }
  transition: all .3s;
`

const raised = css`
  ${props => props.accent && `
    color: white;
    background-color: green;
    &:before {
      color: black;
    }
  `}
  ${props => props.primary && `
    color: white;
    background-color: #5173E7;
    &:before {
      color: black;
    }
  `}
  ${props => props.accent &&
    props.light && `
    color: white;
    background-color: #1fd21f;
    &:before {
      color: black;
    }
  `}
`

const Button = styled.button.attrs({
  'data-smc': 'Button'
})`
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  min-width: 88px;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  outline: none;
  background: transparent;
  font-size: ${props => props.fontSize || 16}px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: color 1s, background 1s;

  &:active {
    outline: none;
    /*background-color: rgba(0, 255, 100, 0.8);*/
  }

  
  ${props => props.hover === undefined && `
  &:hover {
    cursor: pointer;
    background-color: #6C8EF5;
    transition: color .2s, background 1s;
  }
`}
  
  

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }


  ${props => props.mini && mini}
  ${props => props.accent && accent}
  ${props => props.primary && primary}
  ${props => props.raised && raised}
  ${props => props.compact && 'padding: 0 8px;'}
  ${props => props.disabled ? `
    opacity: 0.28;
    cursor: default;
    pointer-events: none;
  ` : 'cursor: pointer;'}
  ${props => props.notClickable && `
    cursor: default;
    pointer-events: none;
    background-color: #EEE;
    color: #777;
    font-weight: normal;
  `}
  ${props => props.dense && `
    height: 32px;
    font-size: .8125rem;
    line-height: 32px;
  `}
  ${props => props.big && `
    height: 64px;
    font-size: 20px;
    line-height: 64px;
    border-radius: 6px;
    padding: 0 32px;
  `}
  ${props => props.medium && `
    height: 40px;
    font-weight: 500;
  `}
  ${props => props.fullWidth && 'width: 100%;'}
  ${props => props.light && light}
  ${props => props.secondary && secondary}

  ${props => props.textColor && `
    color: ${props.textColor};
  `}

  ${props => props.hoverTextColor && `
    &:hover {
      color: ${props.hoverTextColor};
    }
  `}

  ${props => props.backgroundHoverColor && `
    &:hover {
      background-color: ${props.backgroundHoverColor};  
    }
  `}

  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};  
  `}

  white-space: nowrap;
  

`

export default Button
