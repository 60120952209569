import {createContext} from 'react'

const VideoPlayerActionsContext = createContext({
  setVideoPlayerValues: () => {},
  handleQuickRewind: () => {},
  onPlay: () => {},
  onPause: () => {},
  goSlow: () => {},
  goNormal: () => {},
  goFast: () => {},
  goFaster: () => {},
  onSeek: () => {}
})

export default VideoPlayerActionsContext
