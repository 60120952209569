import React, {useState} from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faShareSquare,
  faUnlockAlt,
  faRedo,
  faPlay,
  faDownload,
  faExclamationTriangle,
  faTrashAlt as faTrash,
  faICursor as faCursor
} from '@fortawesome/free-solid-svg-icons'
import {MenuItem, MenuIcon} from 'components/common/Popover'
import Export from 'components/common/EditorV2/Header/Buttons/Export'
import useCollection from 'hooks/useCollection'
import useProfile from 'hooks/useProfile'

const MeniIcon = styled(FontAwesomeIcon)`
  color: rgba(0,0,0,0.2);
  font-size: .8em;
`

// const Paused = ({estimatedOver, onClick, retrying}) => {
//   if (retrying) return <Retry retrying={retrying} />
//   return (
//     <FontAwesomeIcon
//       onClick={onClick}
//       icon={faPause}
//       style={{
//         color: estimatedOver ? '#CCC' : '#7DBB91',
//         height: '11px',
//         cursor: estimatedOver ? 'default' : 'pointer'
//       }} />
//   )
// }

const Retry = ({onClick, retrying, retried}) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      spin={retrying}
      icon={faRedo}
      style={{
        color: retried ? '#CCC' : '#5173E7',
        height: '11px',
        cursor: (retrying || retried) ? 'default' : 'pointer'
      }}
    />
  )
}

export default (props) => {
  const {
    paused,
    shouldBeDoneButIsNot,
    children,
    onRetryComplete, 
    id: collectionId,
    onChangeOwner,
    isProcessing,
    isError,
    onEditName,
    onDelete
  } = props
  const {showShareModal, onRetry, onUnPause, owner, isRetried, isDraftComplete, logs, demo} = useCollection({id: collectionId})
  const {isAdmin, uid} = useProfile()
  const isOwner = owner.uid && owner.uid === uid // is owner of collection

  const [retrying, setRetrying] = useState(!!(isRetried && isProcessing))
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = event => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleRetry = async () => {
    console.log({isRetried, retrying})
    if (!isRetried && !retrying) {
      setRetrying(true)
      await onRetry()
      setRetrying(false)
      onRetryComplete && onRetryComplete()
    }
  }

  const handleUnPause = async () => {
    setRetrying(true)
    await onUnPause()
    onRetryComplete && onRetryComplete()
  }

  if (!isProcessing && retrying && !isDraftComplete) return <Retry  retrying />
  if (shouldBeDoneButIsNot && !paused && !isRetried && !demo) return <Retry retrying={retrying} onClick={handleRetry} />
  if (isRetried && !isProcessing && !isDraftComplete) {
    const footer = logs.length > 0 ? `\n\n\n\n\n------------\nlogs: ${logs.map(e => `${e.details}\n`)}` : ''
    return (
      <MeniIcon icon={faExclamationTriangle} onClick={() => {
        window.Beacon('navigate', '/ask/message')
        window.Beacon('prefill', {
          subject: `Issue with collection ${collectionId}`,
          text: `My Collection ${collectionId} never completed.${footer}`,
        })
        window.Beacon('open')
      }} />
    )
  }
  if (isProcessing) return ''
  return (
    <div>
      <div 
        tabIndex={0}
        role="button" 
        styling="text"
        onKeyDown={(evt) => evt.keyCode === 13 && handleClick()}
        style={{outline: 'none', cursor: 'pointer'}}
        onClick={handleClick} 
      >
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        PaperProps={{
          style: {
            padding: '10px',
            overflowX: 'initial',
            overflowY: 'initial'
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {
          ((isOwner) && (!isProcessing && !isError)) && (
            <React.Fragment>
              <MenuItem onClick={() => {
                handleClose()
                onEditName()
              }}>
                <MenuIcon><MeniIcon icon={faCursor} /></MenuIcon>
                <span>Rename</span>
              </MenuItem>
            </React.Fragment>
          )
        }
        {
          isOwner &&
            <MenuItem onClick={() => {
              handleClose()
              onDelete()
            }} color='#CF4923'>
              <MenuIcon><MeniIcon icon={faTrash} /></MenuIcon>
              <span>Delete</span>
            </MenuItem>
        }
        {
          !isOwner && isAdmin &&
            <MenuItem onClick={() => {
              handleClose()
              onChangeOwner(collectionId)
            }} color='#CF4923'>
              <MenuIcon><MeniIcon icon={faUnlockAlt} /></MenuIcon>
              <span>Become Owner</span>
            </MenuItem>
        }

        {
          paused &&
            <MenuItem onClick={() => {
              handleClose()
              handleUnPause()
            }} color='#CF4923'>
              <MenuIcon><MeniIcon icon={faPlay} /></MenuIcon>
              <span>Try Again</span>
            </MenuItem>
        }
        
        {
          !isProcessing && !isError && !paused ?
            (
              <>
                <MenuItem onClick={() => {
                  // console.log('share', collection.id)
                  handleClose()
                  showShareModal(true)
                }} color='#CF4923'>
                  <MenuIcon><MeniIcon icon={faShareSquare} /></MenuIcon>
                  <span>Share</span>
                </MenuItem>
                <Export>
                  <MenuItem>
                    <MenuIcon><MeniIcon icon={faDownload} /></MenuIcon>
                    <span>Export</span>
                  </MenuItem>
                </Export>
              </>
            ) : ''
        }
      </Popover>
    </div>
  )
}
