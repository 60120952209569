import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import Loader from 'components/common/Loaders/Skeleton'
// import FadeIn from 'react-fade-in'
import {Fade} from '@material-ui/core'
import CountUp from 'react-countup'

import usePlan from 'hooks/usePlan'
import useProfile from 'hooks/useProfile'

const Root = styled.div`
  align-items: flext-start;
  justify-content: flext-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 122px;
  h4 {
    margin-bottom: 12px;
  }
  h5 {
    margin: 0;
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
  }
  .MuiLinearProgress-root, .bar {
    border-radius: 6px;
    height: 6px;
    margin-top: 10px;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #CCC;
  }
  .MuiLinearProgress-barColorSecondary {
   background-color: #1d6fb7;
  }
  .MuiLinearProgress-bar2Buffer {
    background-color: #BBB;
    background: repeating-linear-gradient(
      45deg,
      #AAA,
      #AAA 5px,
      #BBB 5px,
      #BBB 10px
    );
    border-radius: 5px;
  }
  .MuiLinearProgress-dashed {
    display: none;
  }
  .MuiLinearProgress-barColorPrimary {
    ${({over}) => over && `
      background-color: #CCC;
    `}
  }
  small {
    margin: 8px 0 15px 0;
    color: #AAA;
    font-size: .75em;
  }
`

const TimeLeft = ({minutes}) => {
  // console.log({minutes})
  if (minutes > 999) {
    const hours = Math.round(minutes / 60)
    return <span><CountUp start={0} end={hours} duration={2} /> hours</span>
  }
  // we round to the highest minute so no need to show seconds
  // if (minutes < 1) {
  //   return <span><CountUp start={0} end={60 * minutes} duration={2} /> seconds</span>
  // }

  return <span><CountUp start={0} end={minutes} duration={2} /> minutes</span>
}

const UploadProgress = (props) => {
  const {uid, oid, billing, isLoaded} = useProfile()
  const {enterprise, updated, paymentMethod} = billing
  const {fetchUsage, loading, fetchCredits, calcForUsageMeter} = usePlan({oid, uid})
  const [loaded, setLoaded] = useState(false)
  
  const {
    perc,
    estPerc,
    minutesLeft,
    paygMins,
    estimatedDraft,
    draftPlan
  } =  calcForUsageMeter
  
  const init = async () => {
    if (!loading && oid && uid && !draftPlan) {
      await Promise.all([
        fetchUsage(),
        fetchCredits()
      ])
      setLoaded(true)
    } 
  }

  useEffect(() => {
    init()
  }, [oid, updated])

  // enterprise dont show anything
  if (enterprise) return ''

  // Loading....
  if (!isLoaded  || (!loaded && loading && !enterprise)) return <div style={{marginTop: '20px'}}><Loader  width='100px' /><Loader /></div>

  // no plan or big plan or mins and assume there is an admin watching minites
  if ((!draftPlan && !paymentMethod && paygMins === undefined) || draftPlan > 59999) return ''

  const activePlan = draftPlan > 0 && minutesLeft > 0 && billing.subscription
  const fixPaymentMethod = !paymentMethod && paygMins === 0 && !activePlan 
  const showPAYG = paygMins >= 0 || paymentMethod

  if (!showPAYG && !fixPaymentMethod && !activePlan) return <Root><Link to='/plans'><small>No more minutes left.</small></Link> </Root>

  return (
    <Fade in={true} timeout={1000}>
      <Root over={perc >= 100 ? 1 : 0} >
        <h4>Usage</h4>
        {
          activePlan ?
            <>
              <h5>Subscription</h5>
              <LinearProgress style={{marginBottom: '8px'}} variant="buffer" value={perc} valueBuffer={estPerc}  />
              <small style={{marginBottom: '15px'}}>{estimatedDraft >= 0 ? <span><TimeLeft minutes={minutesLeft} /> left</span> : ''} </small>
            </> : ''
        }
        { 
          fixPaymentMethod ? <Link to='/plans'><small>Fix payment method</small></Link> :
            showPAYG ?
              <>
                <h5 style={{marginBottom: '5px'}}>Pay as you go</h5>
                {paygMins ? <small><TimeLeft minutes={paygMins} /> in credits</small> : ''}
              </> : ''
        }
      </Root>
    </Fade>
  )
}

export default UploadProgress
