import client from 'api/client'
import {initAnonUser} from 'utils/index'

export const addWelcomeCollection = ({uid, oid}) =>
  client().post(`/user/${uid}/organization/${oid}/welcome`)

export const getKeys = ({uid}) =>
  client().get(`/user/${uid}/token`)

export const track = ({uid, event, name, action, properties, traits}) => {
  let ajsaid = initAnonUser()
  client().post(`/user/t`, {uid, event, name, action, ajsaid, traits, properties})
}

