import React, {Component} from 'react'
import Overlay, {Modal} from 'components/common/Overlay'
import FadeIn from 'react-fade-in'
import styled from 'styled-components'
import Button from 'components/common/Button'
import ChromeLogo from 'static/images/chrome_logo.png'

const A = styled.a`
  color: #5173E7;
  cursor: pointer;
`

const Secondary = styled.div`

  margin: 20px auto 0px;
`

const P = styled.p`
  line-height: 1.8em;
  color: #666;
  max-width: 467px;
  margin: 26px auto 16px;
  font-size: 15px;
`

export const isNotCompatible = () => (navigator.userAgent.toLowerCase().indexOf('edge') >= 0 || navigator.userAgent.toLowerCase().indexOf('msie') >= 0)

export const isNotChrome = () => (navigator.userAgent.toLowerCase().indexOf('chrome') === -1)

class BrowserSupport extends Component {
  state = {
    show: false
  }

  componentDidMount = () => {
    this.setState({
      show: (isNotCompatible() || this.props.show) || (isNotChrome() && this.props.suggest)
    })
  }

  close = () => {
    this.setState({show: false})
  }

  render = () => {
    const {onGenDoc} = this.props
    const {show} = this.state
    if (show) {
      return (<Overlay dark>

        <FadeIn>
          <Modal>
            <img src={ChromeLogo} alt='Chrome Logo' />
            <h2>{
              isNotCompatible() ? <span>Your browser is not supported</span> : <span>Chrome Preferred</span>
            }</h2>
            <P>
              {
                isNotCompatible()
                  ? <span>
                      Unfortunately, we do not currently support Microsoft Edge or Internet Explorer.
                      Good news is we support Safari, <A href='https://www.mozilla.org/' target='_blank'>Firefox</A> and <A href='https://www.google.com/chrome/' target='_blank'>Chrome</A>.
                    <br />(We suggest Chrome for the richest editing experience.)
                  </span>
                  : <span>We suggest Chrome for the richest editing experience, and support most features with Firefox.</span>
              }
              {onGenDoc && <span>You may also <A onClick={onGenDoc}>Download the transcript in Microsoft Word</A>.</span>}
            </P>
            <br />
            <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>
              <Button primary raised>
                Download Chrome
              </Button>
            </a>
            <Secondary>
              {!isNotCompatible() && !this.props.noclose && <Button secondary onClick={this.close}>Close</Button>}
              <Button secondary onClick={(evt) => {
                window.Beacon('open')
                this.close()
              }}>Get Help</Button>
            </Secondary>
          </Modal>
        </FadeIn>

      </Overlay>)
    } else return ''
  }
}

export default BrowserSupport
