import React from 'react'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Searchbar from 'components/common/Dashboardv2/Searchbar'
import CollectionsFilterOptions from 'components/common/Dashboardv2/CollectionsFilterOptions'
import UsageMeter from './UsageMeter'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
    paddingLeft: 27,
    [theme.breakpoints.down(525)]: {
      alignItems: 'center'
    }
  },
  allFiles: {
    marginBottom: 33,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 0,
    width: 78,
    [theme.breakpoints.down(525)]: {
      width: 170
    }
  },
  projectSubtitle: {
    color: '#37404A',
    fontSize: '12px',
    marginBottom: 10,
    letterSpacing: 2.5,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 3,
    opacity: 0.6,
    height: 32,
    width: 78,
    [theme.breakpoints.down(525)]: {
      width: 170
    }
  },
  newProjectButton: {
    justifyContent: 'left',
    padding: '2px 0px',
    fontSize: 16,
    height: 48,
    width: 140,
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: '#5173E7',
    textTransform: 'none'
  },
  labelIcon: {
    width: 17,
    height: 17
  },
  addIcon: {
    width: 18,
    height: 18
  },
  onDrag: {
    backgroundColor: '#ff6e63',
    color: 'white'
  }
}))

const Sidebar = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1' align='left' className={classes.allFiles}>Dashboard</Typography>
      <Searchbar placeholder='Search...' label='search' />
      <CollectionsFilterOptions />
      <br /><br />
      <UsageMeter />
    </div>
  )
}

export default Sidebar
