import React from 'react'
import styled from 'styled-components'
import skeletonImage from './skeleton.png'

const Image = styled.img`
  width: 200px;
  height: 250px;
  margin-left: 11px;
  margin-bottom: 12px;
  border-radius: 12px;
`

const CardSkeleton = (props) => <Image src={skeletonImage} {...props} />

export default CardSkeleton
