import React from 'react'
import FadeIn from 'react-fade-in'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle as faClose} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'

import Overlay, {Modal} from 'components/common/Overlay'
import Button from 'components/common/Button'
import AccountOptions from './AccountOptions'
import useProfile from 'hooks/useProfile'
import useUpload from 'hooks/useUpload'
// import usePlan from 'hooks/usePlan'

const Close = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  top: 42px;
  left: 42px;
  z-index: 2000;
  color: #777;
  &:hover {
    color: #EEE;
  }
  transition: color 1s;
`

const Root = styled.div`
  text-align: center;
`

const Subtitle = styled.p`
  color: ${props => props.color ? props.color : '#444'};
  line-height: 1.5em;
  font-size: 1.2em;
`

const Subtext = styled.p`
  color: ${props => props.color ? props.color : '#444'};
  line-height: 1em;
  font-size: .9em;
  margin-bottom: 20px;
`

const Header = styled.div`
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default (props) => {
  // console.log('props', props)
  const {organizations, switchOrganization} = useProfile()
  const {isShowOverLimit, showOverLimit} = useUpload()
  // const {usage} = usePlan({oid, uid})

  const onClose = () => showOverLimit(false)

  // console.log({usage})
  return (
    isShowOverLimit
      ? <FadeIn transitionDuration={200}>
        <Overlay dark onClick={onClose}>
          <Root>
            <Close icon={faClose} size={'2x'} onClick={onClose}>Close</Close>
            <FadeIn>
              <Modal>
                <Header>
                  <Subtitle color={'#333'}>Could not find minutes for this Workspace.</Subtitle>
                  <Subtext color={'#999'}>
                    Just upgraded or created a workspace? It may take a minute for credits to apply. 
                    <br />
                    If so, please wait a minute and <a href="/dashboard" style={{color: '#2091d0'}}>try again. Refresh</a>.
                  </Subtext>
                  <div><Button raised primary accent ><Link to='/plans'>Update Your Billing Information</Link></Button></div>

                </Header>
                <AccountOptions organizations={organizations} onSwitchAccount={switchOrganization} />
                <div style={{display: 'flex', alignItems: 'center', color: '#CCC', marginTop: '20px'}}>
                  <Button secondary><Link to='/settings/workspace'>Settings</Link></Button>
                  <div>-</div>
                  <Button secondary onClick={(evt) => {
                    window.Beacon('article', '5c0445ca2c7d3a31944ea0b8')
                    onClose()
                  }}>Get help</Button>
                </div>
              </Modal>
            </FadeIn>
          </Root>
        </Overlay>
      </FadeIn> : ''
  )
}
