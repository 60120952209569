import axios from 'axios'
import {getAuthTokenCookie} from 'utils'
import {getNewUserToken, getCurrentUser} from 'utils/Firebase'
import {getApiUrl, hasAAK} from 'utils/env'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  transformResponse: axios.defaults.transformResponse.concat(
    data => {
      try {
        const {result: {Items}, result} = data
        return Items || result
      } catch (error) {
        return data
      }
    }
  )
})

instance.interceptors.request.use(async function (options) {
  const {uid, organization} = (await getCurrentUser(true))
  const {locale = 'us'} = organization || {}
  // console.log({data: options.data})
  const dataLocale = options.data?.locale
  const optionsLocale = typeof options.locale === 'string' ? options.locale : null
  const forceLocale = dataLocale || optionsLocale || false
  // console.log({forceLocale, options})
  const baseURL = getApiUrl(forceLocale || locale)
  let key = getAuthTokenCookie(locale)
  if (!key && uid) key = await getNewUserToken().id
  options.headers['key'] = key
  options.headers['id'] = uid
  if (hasAAK) options.headers['x-api-key'] = hasAAK
  options.baseURL = baseURL
  return options
}, function (error) {
  console.log('Request error: ', error)
  return Promise.reject(error)
})

instance.interceptors.response.use(null, async (error) => {
  if (error.config && error.response && error.response.status === 403) {
    error.config.headers.key = (await getNewUserToken()).id
    return axios.request(error.config)
  }
  return Promise.reject(error)
})

const client = () => instance

export default client
