import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {firebaseReducer} from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore' // <- needed if using firestore
import {isProd, isDM} from 'utils/env'
import collectionsReducer from 'features/collections'
import docTagsReducer from 'features/docTags'
import highlightsReducer from 'features/highlights'
import speakersReducer from 'features/speakers'
import transcriptsReducer from 'features/transcripts'
import versionsReducer from 'features/versions'
import usersReducer from 'features/users'
import uploaderReducer from 'features/uploader'
import usageReducer from 'features/usage'
import dictionariesReducer from 'features/dictionaries'
import peaksReducer from 'features/peaks'
import correctionsReducer from 'features/corrections'
import creditsReducer from 'features/credits'

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
]

const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    collections: collectionsReducer,
    corrections: correctionsReducer,
    docTags: docTagsReducer,
    highlights: highlightsReducer,
    speakers: speakersReducer,
    transcripts: transcriptsReducer,
    versions: versionsReducer,
    users: usersReducer,
    dictionaries: dictionariesReducer,
    peaks: peaksReducer,
    uploader: uploaderReducer,
    usage: usageReducer,
    credits: creditsReducer,
  },
  devTools: (isProd() && isDM) || !isProd(),
  middleware
})

export default store
