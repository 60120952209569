import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
`

export default () => <ProgressBar><LinearProgress /></ProgressBar>
