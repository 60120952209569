// import {useState, useEffect} from 'react'
import {logout} from 'utils/Firebase'
// import UploadContext from 'context/UploadContext'
// import {useProfileContext} from 'context'
// import useProfile from 'hooks/useProfile'

export default () => {
  // const [defaultLanguage, setLanguage] = useState(null)
  // const {profile, updateProfile} = useProfileContext()
  // const {profile} = useProfile()
  // const {loading} = useContext(UploadContext)

  // const settings = (profile || {}).settings || {}

  // const onSetLanguageDefault = async (language) => {
  //   await updateLanguage(profile.uid, language)
  //   // await updateProfile({...profile, settings: {...profile.settings, defaultLanguage: language}})
  // }

  // const handleSwitchAccount = async (key) => {
  //   if (key && profile.uid) {
  //     setLoading(true)
  //     const updatedProfile = await switchAccount(key, profile)
  //     console.log(updatedProfile)
  //     // updateProfile(updatedProfile)
  //     setLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   setLanguage(settings.defaultLanguage)
  // }, [settings.defaultLanguage])

  return {logout}
}
