import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import {isProd, isDM} from 'utils/env'
import './static/styles/main.css'
import 'react-toastify/dist/ReactToastify.min.css'
import './static/styles/toaster.css'
import './static/fonts/font.css'
import {getCurrentEnv} from 'utils/env'
import * as Sentry from '@sentry/react'
// }

// https://stackoverflow.com/questions/49604821/cache-busting-with-create-react-app/49605422#49605422
// import registerServiceWorker from './registerServiceWorker'
import {unregister} from './registerServiceWorker'

// if (getCurrentEnv === 'prod' && process.env.REACT_APP_SENTRY_RELEASE) {
Sentry.init({
  dsn: 'https://3806769f664e4ec2ae799ee9e56eca54@sentry.io/1320787',
  release: process.env.SENTRY_RELEASE_VERSION,
  environment: getCurrentEnv(),
  debug: false,
  ignoreUrls: [/connect\.facebook\.net/, /en_US\/fbevents\.js/],
  // ignoreErrors: [/Failed to execute 'drawImage' on 'CanvasRenderingContext2D'/]
})
console.log('RELEASE', process.env.SENTRY_RELEASE_VERSION, process.env.BUILD_VERSION, getCurrentEnv())

if (isProd() && !isDM) {
  console.log = () => {}
  console.debug = () => {}
}

ReactDOM.render(<Root />, document.getElementById('root'))
// registerServiceWorker()
unregister()
