import React, {useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useReactMediaRecorder} from 'react-media-recorder'
import SVGInline from 'react-svg-inline'

const RecorderCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  div[class*="render-actions__ActionsWrapper"] {
    top: 70%;
  }
`

const RecorderWrapper = styled.div`
  max-width: 690px;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 4%;
  left: 0;
  right: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.3px;
`

const Underlined = styled.span`
  color: #FC600F;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`

const UploadIcon = styled.span`
  color: #FC600F;
  margin-right: 4px;
`

export const StButton = styled.button`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: rgba(227, 73, 28, 0.8);
  outline: none;
  border: none;
  cursor: pointer;
  margin: 20px;
  :hover {
    background: #fb6d42;
  }
`

export const Border = styled.div`
  background: rgba(255, 255, 255, 0.4);
  height: 80px;
  width: 80px;
  border-radius: 50%;
`

export const RecButton = styled.button`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: rgba(227, 73, 28, 0.8);
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 5;
  :hover {
    background: #fb6d42;
  }
`

export const RecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonBorder = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Instructions = styled.div`
  font-family: Arial;
  font-size: 14px;
  color: #ffffff
  letter-spacing: 1.75px;
  display: flex;
  margin-bottom: 20px;
`

export const InstuctionsHighlight = styled.div`
  font-weight: 700;
  color: #dc6547;
  padding: 0 5px;
`

export const Button = styled.button`
  background: ${(props) => props.backgroundColor ?? 'white'};
  color: ${(props) => props.color ?? 'black'};
  border-radius: 4px;
  height: 40px;
  padding: 0px 18px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  :hover {
    background: #eee;
  }
`

const RecIcon = styled.div`
  width: 16px;
  height: 16px;
  background: #e55226;
  border-radius: 50%;
  float: left;
  margin: 2px 8px;
  margin-left: 0;
`

const Text = styled.div`
  position: absolute;
  top: 50px;
  right: 50px;
  font-family: Menlo, monospace;
  font-size: 28px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  color: #fff;
`

const AudioOnlyLabel = styled.p`
  font-family: Menlo, monospace;
  font-size: 12px;
  z-index: 1000;
  color: rgba(255, 255, 255, 0.7);
  text-align:center;
`

const SVGIcon = styled.div`
  display: flex;
  position: absolute;
  justify-self: center;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  object-fit:cover;
`

const CountdownText = styled.span`
  color: #fff;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  z-index: 1000;
  font-family: Menlo, monospace;
  font-size: 100px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  z-index: 1000;
  color: #fff;
`

const IdleActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  > * {
    margin: 4px;
  }
`

export const ErrorMessage = styled.div`
  position:absolute;
  top:0px;
  left:0px;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  z-index: 1100;
  color: #fff;
  background:#000;
  align-items: center;
`

const MediaRecorder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000;
`

const RecorderState = {
  IDLE: 'IDLE',
  TURNING_CAMERA_ON: 'TURNING_CAMERA_ON',
  TURNING_AUDIO_ONLY_ON: 'TURNING_AUDIO_ONLY_ON',
  AUDIO_ONLY_ON: 'AUDIO_ONLY_ON',
  TURNING_CAMERA_OFF: 'TURNING_CAMERA_OFF',
  CAMERA_ON: 'CAMERA_ON',
  COUNTING_DOWN: 'COUNTING_DOWN',
  RECORDING: 'RECORDING',
  START_RECORDING_AUDIO_ONLY: 'START_RECORDING_AUDIO_ONLY',
  STOPPED : 'STOPPED',
  ERROR : 'ERROR'
}

//the smiley icon
const icon = `
<svg width='210px' height='150px' viewBox='0 0 210 150' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
  <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
    <g transform='translate(-915.000000, -356.000000)' fill='#4D4D4D' fill-rule='nonzero'>
      <path d='M1117.31284,419.636364 C1117.31284,417.512192 1119.03367,415.79021 1121.15642,415.79021 C1123.27917,415.79021 1125,417.512192 1125,
      419.636364 C1125,447.023515 1111.3017,469.453876 1087.80267,485.191015 C1067.98313,498.464025 1042.15567,506 1019.49682,506 C996.229145,
      506 970.976604,499.222345 951.727522,486.61975 C928.403996,471.349569 915,448.691655 915,419.636364 C915,417.512192 916.720828,
      415.79021 918.843578,415.79021 C920.966327,415.79021 922.687155,417.512192 922.687155,419.636364 C922.687155,445.976297 934.696662,
      466.276987 955.936236,480.18278 C973.867198,491.922388 997.657898,498.307692 1019.49682,498.307692 C1040.66212,498.307692 1064.99852,
      491.20678 1083.52721,478.798245 C1105.01628,464.407157 1117.31284,444.272084 1117.31284,419.636364 Z M1079.57501,381.174825 C1072.62783,
      381.174825 1066.99602,375.539249 1066.99602,368.587413 C1066.99602,361.635577 1072.62783,356 1079.57501,356 C1086.52218,356 1092.15399,
      361.635577 1092.15399,368.587413 C1092.15399,375.539249 1086.52218,381.174825 1079.57501,381.174825 Z M962.870012,381.174825 C955.922833,
      381.174825 950.291031,375.539249 950.291031,368.587413 C950.291031,361.635577 955.922833,356 962.870012,356 C969.817192,356 975.448993,
      361.635577 975.448993,368.587413 C975.448993,375.539249 969.817192,381.174825 962.870012,381.174825 Z'></path>
    </g>
  </g>
</svg>
`

const RecordButton = (props) => (
  <RecWrapper>
    <Instructions>
      <div>PRESS</div>
      <InstuctionsHighlight>REC</InstuctionsHighlight>
        WHEN READY
    </Instructions>
    <ButtonBorder>
      <RecButton {...props}/>
    </ButtonBorder>
  </RecWrapper>
)

const StopButton = (props) => (
  <Border>
    <StButton {...props}/>
  </Border>
)

const ErrorView = () => (
  <ErrorMessage>
    Oh snap! Your browser failed to record your video.
    <br/>
    <br/>
    Please restart it and try again 👍
  </ErrorMessage>
)

//shows all the video's seen by the user.
const VideoPreview = ({stream, blobUrl}) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current && stream && stream.active) {
      videoRef.current.srcObject = stream
    }

    if (videoRef.current && blobUrl) {
      videoRef.current.srcObject = null
      videoRef.current.src = blobUrl
    }
    
    if (videoRef.current && blobUrl === null) {
      videoRef.current.src = blobUrl
    }
  }, [stream, blobUrl])

  if(!stream  && !blobUrl) return null

  if (stream || blobUrl) {
    return (
      <Video
        loop={!!blobUrl}
        ref={videoRef}
        autoPlay
        playsInline
      />
    )
  }
  return null
}

const tick = (cb, delay) => {
  return setInterval(() => {
    cb()
  }, delay)
}

const Timer = (props) => {
  const {stop} = props
  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    if(!stop){
      const id = tick(() => setElapsedTime(elapsedTime + 1), 1000)
      return () => clearInterval(id)
    }
    //must return same type as the if path to avoid sonarcube "Function should us return consistently" error.
    return () => clearInterval('')
  },[elapsedTime, stop])

  return (
    <Text>
      <RecIcon />
      <span>{elapsedTime}s</span>
    </Text>
  )
}

const Countdown = (props) => {
  const {start, onCompleted} = props
  const [count, setCountdown] = useState(start)

  useEffect(() => {
    let id

    if (count > 1) {
      id = tick(() => setCountdown(count - 1), 1000)
      return () => clearInterval(id)
    }

    id = tick(() => onCompleted(), 1000)
    return () => clearInterval(id)
  }, [count])

  return <CountdownText>{count}</CountdownText>
}

const IdleActions = (props) => {
  const {handleTurnMyCameraOnClick, handleRecordAudioOnlyClick} = props
  return(
    <Actions>
      <IdleActionsWrapper>
        <Button onClick={handleTurnMyCameraOnClick}>Turn my camera ON</Button>
        <Button onClick={handleRecordAudioOnlyClick}>Record Audio Only</Button>
      </IdleActionsWrapper>
    </Actions>
  )
}

const LoadingActions = () => {
  return(
    <Actions>
      <p>loading...</p>
    </Actions>
  )
}

const CameraOnActions = (props) => {
  const {handleStartRecordClick} = props
  return (
    <Actions>
      <RecordButton onClick={handleStartRecordClick}/>
    </Actions>
  )
}

const AudioOnlyActions = (props) => {
  const {handleStartRecordClick} = props
  return(
    <Actions>
      <div>
        <AudioOnlyLabel>AUDIO ONLY</AudioOnlyLabel>
        <RecordButton onClick={handleStartRecordClick}/>
      </div>
    </Actions>
  )
}

const RecordingActions = (props) => {
  const {handleStopRecordClick} = props
  return(
    <Actions>
      <StopButton onClick={handleStopRecordClick}></StopButton>
    </Actions>
  )
}

const StoppedActions = (props) => {
  const {handleUseAnotherVideoClick} = props  
  return(
    <Actions>
      <React.Fragment>
        <Button className='action-button' onClick={handleUseAnotherVideoClick}>
          Use Another Video/Audio
        </Button>
      </React.Fragment>
    </Actions>
  )
}

const Recorder = (props) => {
  const {onUpload, showFileUploader, closing} = props
  const [recorderState, setRecorderState] = useState(RecorderState.IDLE)
  const [video, setVideo] = useState(true)
  const [initialPreviewStream, setInitialPreviewStream] = useState()
  const [blobUrl, setBlobUrl] = useState()
  const onStop = (blobUrl, blob) => {
    setBlobUrl(blobUrl)
    onUpload(blob)
  }

  const {
    error,
    status,
    startRecording,
    stopRecording,
  } = useReactMediaRecorder({audio : true, video, onStop})

  useEffect(() => {
    if(closing){
      stopInitialPreviewStream(initialPreviewStream)
      if(status === 'recording') stopRecording()
    }
  },[closing, initialPreviewStream])

  //gets a stream thats shown to the user after they turn on camera and throughout recording
  const getInitialPreviewStream = async (constraints) => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((err) => {
          // there's a bug in chrome in some windows computers where using `ideal` in the constraints throws a NotReadableError
          try{
            if (
              err.name === 'NotReadableError' ||
              err.name === 'OverconstrainedError'
            ) {
              console.warn(
                `Got ${err.name}, trying getUserMedia again with fallback constraints`
              )
              
              navigator.mediaDevices.getUserMedia({
                audio: false,
                video: true
              })
            }
          }catch(err){
            reject(err)
          }

          reject(err)
        })
        .then((stream) => {
          resolve(stream)
        })
    })
  }

  const stopInitialPreviewStream = (stream) => {
    setInitialPreviewStream(null)
    stream && stream.getTracks().forEach((track) => track.stop())
  }

  const handleError = (err) => {
    setRecorderState(RecorderState.ERROR)
    initialPreviewStream && stopInitialPreviewStream(initialPreviewStream)
  }

  const handleStartRecordClick = (event) => {
    setRecorderState(RecorderState.COUNTING_DOWN)
  }

  const handleRecordAudioOnlyClick = (event) => {
    setRecorderState(RecorderState.TURNING_AUDIO_ONLY_ON)
    setVideo(false)
    setRecorderState(RecorderState.AUDIO_ONLY_ON)
  }

  const handleTurnMyCameraOnClick = async (event) => {
    setRecorderState(RecorderState.TURNING_CAMERA_ON)
    try{
      const stream = await getInitialPreviewStream({audio: false, video})
      setInitialPreviewStream(stream)
      setRecorderState(RecorderState.CAMERA_ON)
    }catch(err){
      handleError(err)
    }
  }

  const handleCountdownCompleted = () => {
    //change state from counting down to ensure we stop showing the countdown
    setRecorderState(RecorderState.RECORDING)
    startRecording()
  }

  const handleStopRecordClick = (event) => {
    stopRecording()
    setRecorderState(RecorderState.STOPPED)
    initialPreviewStream && stopInitialPreviewStream(initialPreviewStream)
  }

  const handleUseAnotherVideoClick = (event) => {
    setRecorderState(RecorderState.IDLE)
    //so we don't see the video preview in idle mode
    setBlobUrl(null)
    setVideo(true)
  }

  const SVGBgIcon = (
    <SVGIcon>
      <SVGInline svg={icon} />
    </SVGIcon>
  )

  //having a second VideoPreview for rendering the result removes flickers caused by state changes 
  //when showing the result.
  return (
    <RecorderCont>
      <RecorderWrapper>

        <MediaRecorder>
          {((recorderState === RecorderState.ERROR) || (error.length > 0)) && <ErrorView/>}
          {recorderState === RecorderState.IDLE && SVGBgIcon}
          {recorderState === RecorderState.IDLE && <IdleActions {...{handleTurnMyCameraOnClick, handleRecordAudioOnlyClick}} />}
          {recorderState === RecorderState.TURNING_CAMERA_ON && <LoadingActions/>}
          {recorderState === RecorderState.CAMERA_ON && <CameraOnActions {...{handleStartRecordClick}}/>}
          {recorderState === RecorderState.AUDIO_ONLY_ON && <AudioOnlyActions {...{handleStartRecordClick}}/>}
          {recorderState === RecorderState.COUNTING_DOWN && <Countdown start={3} onCompleted={handleCountdownCompleted}/>}
          {status === 'recording' && !((recorderState === RecorderState.ERROR) || (error.length > 0)) && <RecordingActions {...{handleStopRecordClick}}/>}
          {status === 'recording' && !((recorderState === RecorderState.ERROR) || (error.length > 0)) && <Timer stop={recorderState === RecorderState.STOPPED}/>}
          {recorderState === RecorderState.STOPPED && <StoppedActions {...{handleUseAnotherVideoClick}}/>}

          {!blobUrl && <VideoPreview
            blobUrl={null}
            stream={initialPreviewStream}
          />}
          {blobUrl && <VideoPreview
            blobUrl={blobUrl}
            stream={null}
          />}
        </MediaRecorder>
      </RecorderWrapper>
      <Message onClick={showFileUploader}>
        <UploadIcon>⬆</UploadIcon> Or&nbsp;<Underlined>upload</Underlined>&nbsp;a file from your computer
      </Message>
    </RecorderCont>
  )
}

export default Recorder